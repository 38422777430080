import '../Css/Components/MasdarCity.css';
import React, { useRef } from 'react';
import Slider from 'react-slick'
import ClientSlider from './ClientSlider.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

let clients = [
    {
        name: "Business Package",
        disc: "The Business Package is designed to maximize flexibility for companies with specific requirements and is available to new and existing businesses.",
    },
    {
        name: "Women Entrepreneurship Package",
        disc: "The Women Entrepreneurship Package is exclusively for women entrepreneurs and is designed to drive the growth of women-led companies in the United Arab Emirates.",
    },
    {
        name: "Start-Up Package",
        disc: "The Start-Up Package is designed to support start-up ventures and foster innovation.",
    }
]
var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    responsive: [
        {
            breakpoint: 990,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 530,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}


const Dwtc = () => {
    const arrowRef = useRef(null);
    let clientDisc = "";
    clientDisc = clients.map((item, i) => (
        <ClientSlider item={item} key={i} />
    ))
    return (
        <>
            <div className="page-hero dwtc">
                <div className="page-hero-top">
                    <div className="main">
                        <div className="content">
                            <div className="content-logo">
                                <img src="../assets/dwtc/logo.webp" alt="" />
                            </div>
                            <div className="heading">
                                <h2>
                                    Set Up Your Company with
                                </h2>
                                <h1 className='orange'>
                                    Dubai World Trade Centre
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-form">
                    <div className="main">
                        <div className="form">
                            <div className="sub" onClick={(e) => e.stopPropagation()}>
                                <form id="contactForm">
                                    <input type="text" name="name" placeholder="Full Name" />
                                    <input type="tel" name="phoneNumber" placeholder="Phone Number" />
                                    <input type="email" name="email" placeholder="Email" />
                                    <input type="text" name="subject" placeholder="Subject" />
                                    <textarea name="message" rows="4" placeholder="Message" />
                                    <input type="submit" value="SEND MESSAGE" className="btn-submit" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-bottom">
                    <div className="main">
                        <div className="content">
                            <h2>
                                About
                                <span className='orange'>&nbsp;Dubai&nbsp;World&nbsp;
                                </span><br /><span className='orange'>Trade&nbsp;Centre</span>
                            </h2>
                            <p>
                                Dubai World Trade Centre, the city’s first major tower, is a global business hub, offering lucrative benefits to companies seeking international exposure and stability within the region. It provides a wide range of services, from event management and marketing to technology and security.
                            </p>
                            <p>
                                Situated in the heart of Dubai, DWTC is a well-known and respected venue for international trade shows, conferences, and other business events, making it an ideal choice for businesses looking to host different events in the city.
                            </p>
                            <p>
                                DWTC is home to leading international companies such as Shell, FedEx, General Motors, and Halliburton.
                            </p>
                        </div>
                        <div className="logo">
                            <div className="main">
                                <img src="../assets/logo/logo.png" alt="" />
                            </div>
                        </div>

                    </div>
                </div>


                {/* Page Advantage */}
                {/* Page Advantage */}
                {/* Page Advantage */}
                <div className="page-hero-adavntage">
                    <div className="main">
                        <div className="content">
                            <div className="heading">
                                <h1>
                                    Advantages of Setting Up Your Business
                                    <br />
                                    <span className='orange'>in Dubai World Trade Center</span>
                                </h1>
                                <p>
                                    DWTC provides favorable incentives and countless benefits for entrepreneurs looking to start a business in the UAE. Here are some of the following:
                                </p>
                            </div>
                            <div className="list">
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>
                                        <div className="heading">
                                            <h3>
                                                Strategic Location
                                            </h3>
                                        </div>
                                        <div className="dis">
                                            <ul>
                                                <li>
                                                    DWTC is located just 15 minutes away from Dubai International Airport, giving easy access to different locations in Dubai.
                                                </li>
                                                <li>
                                                    It is in proximity to Burj Khalifa, the Emirates Towers, and the Dubai International Financial Center.
                                                </li>
                                            </ul>
                                        </div>

                                    </div>

                                </div>
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>
                                        <div className="heading">
                                            <h3>
                                                Globally Recognized District
                                            </h3>
                                        </div>
                                        <div className="dis">
                                            <ul>
                                                <li>
                                                    DWTC is a leading venue for some of the world’s most prestigious events. It hosts more than 500 international trade fairs, exhibitions, and conventions, welcoming over 3 million visitors annually.
                                                </li>

                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>
                                        <div className="heading">
                                            <h3>
                                                Flexible Office Leasing Options
                                            </h3>
                                        </div>
                                        <div className="dis">
                                            <ul>
                                                <li>
                                                    Holders of DWTC Authority licenses and business operating permits are allowed to sub-lease premises to other Free Zone members.
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>
                                        <div className="heading">
                                            <h3>
                                                Favorable Legal Framework
                                            </h3>
                                        </div>
                                        <div className="dis">
                                            <ul>
                                                <li>
                                                    100% foreign company ownership
                                                </li>
                                                <li>
                                                    No restrictions on capital repatriation
                                                </li>
                                                <li>
                                                    No currency restrictions
                                                </li>
                                                <li>
                                                    No personal income tax
                                                </li>
                                                <li>
                                                    Flexible commercial lease terms
                                                </li>
                                                <li>
                                                    Only 9% corporate income tax (if applicable)
                                                </li>
                                                <li>
                                                    Low tariffs
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>
                                        <div className="heading">
                                            <h3>
                                                Dual Licensing
                                            </h3>
                                        </div>
                                        <div className="dis">
                                            <ul>
                                                <li>
                                                    For mainland companies who want to expand their operation within DWTC’s free zone jurisdiction, business operating permits are issued to them. However, companies granted this permit can only carry out activities aligned with their DED license.
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>

            </div>


            {/* Packages Option */}
            {/* Packages Option */}
            {/* Packages Option */}

            <div className="page-option-licence">
                <div className="main">
                    <div className="heading">
                        <h1>
                            Types of Licenses Offered
                            <br />
                            <span className='orange'>by Dubai World Trade Centre </span>
                        </h1>
                    </div>
                    <div className="list">
                        <div className="item">
                            <div className="image">
                                <img src="../assets/dwtc/service/01.webp" alt="" />
                            </div>
                            <div className="heading">
                                <h3>General Trading License</h3>
                            </div>
                            <div className="dis">
                                <p>
                                    Permits companies to engage in commercial activities in a wide range of business activities, including exporting, importing, and distributing goods.
                                </p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="image">
                                <img src="../assets/dwtc/service/02.webp" alt="" />
                            </div>
                            <div className="heading">
                                <h3>Commercial License</h3>
                            </div>
                            <div className="dis">
                                <p>
                                    Allows companies to carry out activities specified in the license to export, import, distribute, sell and store items permissible under the license.
                                </p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="image">
                                <img src="../assets/dwtc/service/03.webp" alt="" />
                            </div>
                            <div className="heading">
                                <h3>Professional License</h3>
                            </div>
                            <div className="dis">
                                <p>
                                    This license permits businesses to carry out services such as general or advisory consulting, onsite repair, training, and HR and software implementation services.
                                </p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="image">
                                <img src="../assets/dwtc/service/04.webp" alt="" />
                            </div>
                            <div className="heading">
                                <h3>Event management License</h3>
                            </div>
                            <div className="dis">
                                <p>
                                    Permits companies to organize business or leisure events, conferences, incentives, meetings, exhibitions, and conferences within the jurisdiction.
                                </p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="image">
                                <img src="../assets/dwtc/service/05.webp" alt="" />
                            </div>
                            <div className="heading">
                                <h3>E-commerce License</h3>
                            </div>
                            <div className="dis">
                                <p>
                                    This license is intended for businesses with an online platform, allowing them to trade goods and services online.
                                </p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="image">
                                <img src="../assets/dwtc/service/06.webp" alt="" />
                            </div>
                            <div className="heading">
                                <h3>Single Family Office</h3>
                            </div>
                            <div className="dis">
                                <p>
                                    Intended for offices established by family members to manage and service the wealth of a single family.
                                </p>
                            </div>

                        </div>

                    </div>
                </div>

            </div>




            {/* Page Packages */}
            {/* Page Packages */}
            {/* Page Packages */}

            <div className="page-packages">


                {/* Page Package Zoon Slider */}
                {/* Page Package Zoon Slider */}
                {/* Page Package Zoon Slider */}

                <div className="package-zoon-slider">
                    <div className="bg-dubai">
                        <img src="../assets/contactus/dubai.png" alt="" />
                    </div>
                    <div className="main">
                        <div className="content">
                            <div className="heading">
                                <h1>
                                    Dubai World Trade Centre
                                    <span className='orange'> Packages</span>
                                </h1>
                            </div>
                            <div className="slide">
                                <Slider ref={arrowRef} {...settings}>
                                    {clientDisc}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Packages Steps */}
            {/* Packages Steps */}
            {/* Packages Steps */}

            <div className="packages-steps">
                <div className="main">
                    <div className="sticky">
                        <div className="heading">
                            <h2>
                                Steps for <br />
                                Company Formation in
                            </h2>
                            <h1 className='orange'>
                                Dubai World Trade Centre
                            </h1>

                            <div className="dis">
                                <p>
                                    Here are the general steps involved in setting up your business in DWTC.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="move">
                        <div className="list">
                            <div className="item">
                                <div className="number">
                                    <h1>1</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Choose a Business Activity and Apply for a License
                                    </h2>
                                    <hr />
                                    <p>
                                        The first step in setting up a business in Dubai World Trade Center (DWTC) is to choose a business activity and apply for a license. Depending on the activity type, various government bodies, such as the Department of Economic Development (DED), issue licenses for companies.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="number">
                                    <h1>2</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Register with the DWTC
                                    </h2>
                                    <hr />
                                    <p>
                                        Once the license is granted, the next step is registering your business with the DWTC by submitting the essential documents required for your company. This can be done either by visiting the DWTC offices or online.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="number">
                                    <h1>3</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Rent or Purchase Office Space
                                    </h2>
                                    <hr />
                                    <p>
                                        The next step is to rent or purchase office space in the DWTC. Several options are available, including office spaces, furnished offices, and flexi offices.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="number">
                                    <h1>4</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Process UAE Residency and Apply for a Business Bank Account
                                    </h2>
                                    <hr />
                                    <p>
                                        Once you have collected your trade license and signed a lease, it is now time to arrange your residency visa and open a corporate bank where your company’s financial transactions will be done.
                                        <br />
                                        Creative Zone will help you throughout this stage to ensure that all laws in Dubai are followed for a faster, inexpensive, and seamless company incorporation.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="number">
                                    <h1>5</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Receive Your Business License
                                    </h2>
                                    <hr />
                                    <p>
                                        Ultimately, you will obtain your license and certificate of registration/incorporation, accessible via the Masdar Free Zone’s online platform. Future Innovative will provide support throughout the company formation process, ensuring that your business setup journey is streamlined and hassle-free.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>








        </>
    )
}

export default Dwtc;
