import React from 'react';
import Landing from '../componenets/Landing.js';
import Steps from '../componenets/Steps.js';
import Calculator from '../componenets/Calculator.js';
import Choose from "../componenets/Choose.js";
import Why from "../componenets/Why.js";
import Team from '../Components/Team.js';
import Need from "../componenets/Need.js";
import ContactUs from '../Components/ContactUs.js';
import Header from '../componenets/Header.js';

export default function Home() {
    return (
        <main>
            <Header/>
            <Landing />
            <Steps />
            <Choose />
            <Calculator />
            <Why />
            <Team />
            <Need />
            <ContactUs />
        </main>
    )
}
