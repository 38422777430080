import React from 'react';
import '../Css/ClientSlider.css';

const ClientSlider = (props) => {
    const { name, stars, disc, disc1, disc2, disc3 } = props.item;
    return (
        <div className='package-slider'>
            <div className="part">
                <div className="name">
                    <h2 className='orange'>{name}</h2>
                </div>

                <div className='dis'>
                    <h6>
                        {disc}
                    </h6>
                </div>
            </div>
        </div>
    )
}

export default ClientSlider