import "../Css/Need.css"
import React from 'react'

export default function Need() {
    return (
        <div className="need-ccomponenets page">
        <div className="need-ccomponenets-main">
            <div className="need-ccomponenets-card exp1">
                <div className="deatils">
                    <div className="main">
                        <h1 className="display4 orange">
                            Need a
                            
                            {/* <span className="display5 white ">
                           
                                </span>  */}
                        </h1>
                        <h2 className="white">
                        Bank Account
                     </h2>
                        <hr />
                        <p >
                            Opening a corporate bank account is integral to the business setup process. Creative Zone has established partnerships with multiple banks, and we will find the bank that best suits your needs. We will guide you through opening your corporate bank account and assist you in preparing all the requirements to get started.
                         </p>
                        <p className='special' >
                             </p>
                        <button>
                            Learn More
                        </button>
                    </div>
                </div>
            </div>
            <div className="need-ccomponenets-card exp2">
                <div className="deatils">
                    <div className="main">
                    <h1 className="display4 orange">
                            Need a
                      
                            {/* <span className="display6 white ">
                            co-working solutions
                                </span>  */}

                     </h1>
                     <h2 className="white">
                     co-working solutions
                     </h2>
                        <hr />
                        <p className="white" >
                            Discover the perfect jurisdiction for your business activity from a diverse range of over 50 free zones in the UAE.
                        </p>
                        <p className='special' >
                            For multinational companies, free zone company setups offer significant advantages. Enjoy full foreign company ownership, benefit from the attractive low tax rates, and tap into the dynamic business ecosystem of the UAE.
                        </p>
                        <button>
                            Learn More
                        </button>
                    </div>
                </div>
            </div>
            <div className="need-ccomponenets-card exp3">
                <div className="deatils">
                    <div className="main">
                    <h1 className="display4 orange">
                          Need a
                         
                        </h1>
                        <h2 className="white">
                        Family Visas
                     </h2>
                     <hr />
                        <p  className="white">
                            Conduct business activities without the need for any physical presence in the UAE. Just like in Mainland and Free Zone, enjoy a favorable business ecosystem in a more convenient setup.
                        </p>
                        <p className='special' >
                            Offshore companies also get access to the UAE’s wide range of services, such as trade finance, business consultancy, and legal and financial advice.
                        </p>
                        <button>
                            Learn More
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
