import "../Css/Landing.css"
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef } from 'react';

gsap.registerPlugin(ScrollTrigger);
const Landing = () => {
    const landingMain = useRef();

    useLayoutEffect(() => {

        let ctx = gsap.context(() => {
            const hedert1 = gsap.timeline();


            hedert1.from(".landing-heading1", {
                yPercent: 100,
                duration: 1,
                opacity: 0,
                stagger: 1,

            });


            const hedert2 = gsap.timeline();

            // hedert2.to(".hea", {
            //     yPercent: 150,
            //     duration: 0.5,
            //     stagger: 0.25,
            // },);


            // ScrollTrigger.create({
            //     trigger: ".header-top",
            //     start: "250 230",
            //     end: `${document.body.scrollHeight} bottom`,
            //     toggleActions: "restart none none reverse",
            //     animation: hedert2,
            //     markers: false
            // });

        });
        return () => ctx.revert();
    });
    return (
        <main ref={landingMain}>
            <div className="landing">
                <div className="landing-main">
                   
                    <h1 className="landing-heading1 display1">BUSINESS SETUP</h1>
                    <h1 className="landing-heading2 display1">IS JUST THE BEGINNING</h1>
                </div>
            </div>

        </main>
    )
}

export default Landing

