import '../Css/Components/MasdarCity.css';
import React, { useRef } from 'react';
import Slider from 'react-slick'
import ClientSlider from './ClientSlider.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

let clients = [
    {
        name: "Business Package",
        disc: "The Business Package is designed to maximize flexibility for companies with specific requirements and is available to new and existing businesses.",
    },
    {
        name: "Women Entrepreneurship Package",
        disc: "The Women Entrepreneurship Package is exclusively for women entrepreneurs and is designed to drive the growth of women-led companies in the United Arab Emirates.",
    },
    {
        name: "Start-Up Package",
        disc: "The Start-Up Package is designed to support start-up ventures and foster innovation.",
    }
]
var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    responsive: [
        {
            breakpoint: 990,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 530,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}


const D3 = () => {
    const arrowRef = useRef(null);
    let clientDisc = "";
    clientDisc = clients.map((item, i) => (
        <ClientSlider item={item} key={i} />
    ))
    return (
        <>
            <div className="page-hero d3">
                <div className="page-hero-top">
                    <div className="main">
                        <div className="content">
                            <div className="content-logo">
                                <img src="../assets/d3/logo.webp" alt="" />
                            </div>
                            <div className="heading">
                                <h2>
                                    Set Up Your Company in
                                </h2>
                                <h1 className='orange'>
                                    Dubai Design District
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-form">
                    <div className="main">
                        <div className="form">
                            <div className="sub" onClick={(e) => e.stopPropagation()}>
                                <form id="contactForm">
                                    <input type="text" name="name" placeholder="Full Name" />
                                    <input type="tel" name="phoneNumber" placeholder="Phone Number" />
                                    <input type="email" name="email" placeholder="Email" />
                                    <input type="text" name="subject" placeholder="Subject" />
                                    <textarea name="message" rows="4" placeholder="Message" />
                                    <input type="submit" value="SEND MESSAGE" className="btn-submit" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-bottom">
                    <div className="main">
                        <div className="content">
                            <h2>
                                About
                                <span className='orange'>&nbsp;Dubai Design District</span>
                            </h2>
                            <p>
                                Dubai Design District, commonly known as D3, is the UAE’s creative hub dedicated to developing the design industry and promoting the city as a global design capital. D3 is situated in the heart of Dubai, close to iconic landmarks, such as the Burj Khalifa and Dubai Mall. The district covers an area of over 4 million square feet and is home to over 500 businesses, including world-renowned design studios, showrooms, galleries, and fashion brands, including Dior, Adidas, Nike, and Foster + Partners.
                            </p>
                            <h2>
                                <span className='orange'>Dubai Design Week</span>
                            </h2>
                            <p>
                                Annually held in D3, Dubai Design Week is the region’s creative festival, showcasing architecture, fashion, product design, and graphic designs. The event usually includes exhibitions, talks, workshops, and other activities. Dubai Design Week is one of the most important events in the UAE, as it brings together designers, artists, and other creative professionals from around the world.
                            </p>
                        </div>
                        <div className="logo">
                            <div className="main">
                                <img src="../assets/logo/logo.png" alt="" />
                            </div>
                        </div>

                    </div>
                </div>


                {/* Page Advantage */}
                {/* Page Advantage */}
                {/* Page Advantage */}
                <div className="page-hero-adavntage">
                    <div className="main">
                        <div className="content">
                            <div className="heading">
                                <h1>
                                    Advantages of Setting Up Your Business
                                    <br />
                                    <span className='orange'>in Dubai Design District</span>
                                </h1>
                                <p>
                                    DWTC provides favorable incentives and countless benefits for entrepreneurs looking to start a business in the UAE. Here are some of the following:
                                </p>
                            </div>
                            <div className="list">
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>
                                        <div className="heading">
                                            <h3>
                                                Modern and Advanced Infrastructures
                                            </h3>
                                        </div>
                                        <div className="dis">
                                            <p>
                                                D3 is a smart zone hub. These initiatives include smart parking meters, smart energy meters, and smart waste management, among others.
                                            </p>
                                        </div>

                                    </div>

                                </div>
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>
                                        <div className="heading">
                                            <h3>
                                                Proximity to World-Renowned Landmarks
                                            </h3>
                                        </div>
                                        <div className="dis">
                                            <p>
                                                Dubai Design District is located close to Dubai Mall, the largest mall in the UAE, with an annual visitor count of 65 million, as well as to Burj Khalifa, Dubai Internet City clusters, DIFC, and Dubai’s Business Bay.
                                            </p>
                                        </div>

                                    </div>
                                </div>
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>
                                        <div className="heading">
                                            <h3>
                                                Low-Cost Company Setup
                                            </h3>
                                        </div>
                                        <div className="dis">
                                            <p>
                                                Forming a company under D3 is relatively inexpensive. The license cost in Dubai District starts from around AED 15,000, and the minimum share capital required is as low as AED 50,000.
                                            </p>
                                        </div>

                                    </div>
                                </div>
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>
                                        <div className="heading">
                                            <h3>
                                                World-Class Recognition
                                            </h3>
                                        </div>
                                        <div className="dis">
                                            <p>
                                                Every year, designers and artists are given a chance to showcase their work to world-renowned designers through Dubai Design Week.
                                            </p>
                                        </div>

                                    </div>
                                </div>
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>
                                        <div className="heading">
                                            <h3>
                                                Legal Framework
                                            </h3>
                                        </div>
                                        <div className="dis">
                                            <ul>
                                                <li>
                                                    Company ownership
                                                </li>
                                                <li>
                                                    No restrictions on capital repatriation
                                                </li>
                                                <li>
                                                    No currency restrictions
                                                </li>
                                                <li>
                                                    No personal income tax
                                                </li>
                                                <li>
                                                    No restrictions for foreign employees
                                                </li>
                                                <li>
                                                    Only 9% corporate income tax (if applicable)
                                                </li>
                                                <li>
                                                    Low tariff rates
                                                </li>
                                                <li>
                                                    Special freelance permit for designers and artists
                                                </li>

                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>

            </div>


            {/* Packages Option */}
            {/* Packages Option */}
            {/* Packages Option */}

            <div className="page-option-licence">
                <div className="main">
                    <div className="heading">
                        <h1>
                            Types of Licenses Offered
                            <br />
                            <span className='orange'>in Dubai Design District </span>
                        </h1>
                    </div>
                    <div className="list">
                        <div className="item">
                            <div className="image">
                                <img src="../assets/d3/servies/01.webp" alt="" />
                            </div>
                            <div className="heading">
                                <h3>Manufacturing License</h3>
                            </div>
                            <div className="dis">
                                <p>
                                    Allows companies to engage in manufacturing, processing or assembling activities within D3.
                                </p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="image">
                                <img src="../assets/d3/servies/02.webp" alt="" />
                            </div>
                            <div className="heading">
                                <h3>Commercial License</h3>
                            </div>
                            <div className="dis">
                                <p>
                                    Intended for businesses offering specific goods and services within D3.
                                </p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="image">
                                <img src="../assets/d3/servies/03.webp" alt="" />
                            </div>
                            <div className="heading">
                                <h3>Service License</h3>
                            </div>
                            <div className="dis">
                                <p>
                                    Allows businesses to provide services (e.g. repair and maintenance services) within D3.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>




            {/* Page Packages */}
            {/* Page Packages */}
            {/* Page Packages */}

            <div className="page-packages">


                {/* Page Package Zoon Slider */}
                {/* Page Package Zoon Slider */}
                {/* Page Package Zoon Slider */}

                <div className="package-zoon-slider">
                    <div className="bg-dubai">
                        <img src="../assets/contactus/dubai.png" alt="" />
                    </div>
                    <div className="main">
                        <div className="content">
                            <div className="heading">
                                <h1>
                                    Business Structures
                                    <span className='orange'> in Dubai Design District</span>
                                </h1>
                            </div>
                            <div className="slide">
                                <Slider ref={arrowRef} {...settings}>
                                    {clientDisc}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Packages Steps */}
            {/* Packages Steps */}
            {/* Packages Steps */}

            <div className="packages-steps">
                <div className="main">
                    <div className="sticky">
                        <div className="heading">
                            <h2>
                                Steps in Setting
                                <br />
                                Up Your Business in
                            </h2>
                            <h1 className='orange'>
                                Dubai Design District
                            </h1>

                            <div className="dis">
                                <p>
                                    Starting a company in D3 is fast and easy. Generally, this includes five steps
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="move">
                        <div className="list">
                            <div className="item">
                                <div className="number">
                                    <h1>1</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Select a Business Activity and Location
                                    </h2>
                                    <hr />
                                    <p>
                                        Before you start, you must select one of the approved business activities permitted in Dubai Design District. Companies in D3 have the option to select where they want to conduct business – from the Free Zone to the mainland or both.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="number">
                                    <h1>2</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Submit Application
                                    </h2>
                                    <hr />
                                    <p>
                                        Businesses must complete the online application and submit business plans and passports for all shareholders. Creative Zone can assist you throughout this process to ensure faster, less-expensive company incorporation.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="number">
                                    <h1>3</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Secure Physical Space
                                    </h2>
                                    <hr />
                                    <p>
                                        Companies operating within the Dubai Design District jurisdiction must secure office space or a warehousing facility dedicated to their business. Freelancers may be exempted from this requirement.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="number">
                                    <h1>4</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Apply for Trade License
                                    </h2>
                                    <hr />
                                    <p>
                                        After securing an office space, companies will need to apply for the appropriate trade license through the Dubai Design District. Depending on the nature of the products and services offered, companies may be required to apply for multiple trade licenses.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="number">
                                    <h1>5</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Open a Bank Account
                                    </h2>
                                    <hr />
                                    <p>
                                        After obtaining all the relevant documents, you can now proceed in opening a corporate bank account. This is where all the financial transactions of your company will be made while operating under the jurisdiction of Dubai Design District.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>








        </>
    )
}

export default D3;
