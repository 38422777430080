import '../Css/Components/MasdarCity.css';
import React, { useEffect, useRef } from 'react'
import ClientSlider from './ClientSlider.js';
import '../Css/Components/Need.css';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

let clients = [
    {
        name: "Business Package",
        disc: "The Business Package is designed to maximize flexibility for companies with specific requirements and is available to new and existing businesses.",
    },
    {
        name: "Women Entrepreneurship Package",
        disc: "The Women Entrepreneurship Package is exclusively for women entrepreneurs and is designed to drive the growth of women-led companies in the United Arab Emirates.",
    },
    {
        name: "Start-Up Package",
        disc: "The Start-Up Package is designed to support start-up ventures and foster innovation.",
    }
]
var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    responsive: [
        {
            breakpoint: 990,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 530,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}


const BusinessHub = () => {
    const headingRef1 = useRef(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const heading = headingRef1.current;

        const th = gsap.timeline({
            scrollTrigger: {
                trigger: heading,
                start: "top 85%",
                end: "bottom 0%",
                toggleActions: "restart none none none",

            }
        });

        th.from(heading, {
            opacity: 0,
            y: "+=50",
            duration: 0.3
        }).to(heading, {
            opacity: 1,
            y: 0,
        });
        return () => {
            th.kill(); // Kill the timeline to revert the animation
        };
    }, []);


    const arrowRef = useRef(null);
    let clientDisc = "";
    clientDisc = clients.map((item, i) => (
        <ClientSlider item={item} key={i} />
    ));


    return (
        <>
            <div className="page-hero business">
                <div className="page-hero-top">
                    <div className="main">
                        <div className="content">
                            <div className="content-logo">
                            </div>
                            <div className="heading">

                                <h1>
                                    Smart Co-working Space <br />
                                    in <span className='orange'>FUTURE INNOVATIVE</span>
                                </h1>
                                <br />
                                <h3>
                                    Exceptional office amenities and
                                    <br />
                                    support in the center of Dubai
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-form">
                    <div className="main">
                        <div className="form">
                            <div className="sub" onClick={(e) => e.stopPropagation()}>
                                <form id="contactForm">
                                    <input type="text" name="name" placeholder="Full Name" />
                                    <input type="tel" name="phoneNumber" placeholder="Phone Number" />
                                    <input type="email" name="email" placeholder="Email" />
                                    <input type="text" name="subject" placeholder="Subject" />
                                    <textarea name="message" rows="4" placeholder="Message" />
                                    <input type="submit" value="SEND MESSAGE" className="btn-submit" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-bottom">
                    <div className="main">
                        <div className="content">
                            <h2>
                                FUTURE INNOVATIVE
                                <br />
                                <span className='orange'>BUSINESS HUB</span>
                            </h2>
                            <p>
                                Should you wish your office be located in one of Dubai’s most prestigious Downtown Dubai addresses, we recommend our co-working and meeting facility at Emaar Square Building 4, Downtown, Dubai.
                            </p>
                            <p>
                                Experience state-of-the-art office facilities paired with personalized customer support that’s second to none, including a wide range of concierge services to help you manage your operations successfully.
                            </p>
                        </div>
                        <div className="logo">
                            <div className="main">
                                <img src="../assets/logo/logo.png" alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            {/* Hover */}

            <div className="need-ccomponenets business">
                <div className="headings" >
                    <h1 ref={headingRef1}>Event Space<strong className='orange'>&nbsp;FUTURE INNOVATIVE</strong></h1>
                </div>
                <div className="need-ccomponenets-main">
                    <div className="need-ccomponenets-card busi1">
                        <div className="deatils">
                            <div className="main">
                                <h1>
                                    <br />
                                    MeetingRoom
                                </h1>
                                <hr />
                                <p >
                                    FUTURE INNOVATIVE clients: Complimentary one hour for event space bookings (minimum of 3 hours booked).
                                    <br />
                                    <br />
                                    Valued at AED 299 per hour x 50 persons. Sunday to Thursday 8 am – 9 pm (For weekend bookings, minimum of 4 hours booked at AED 499/per hour).
                                    <br />
                                    <br />
                                    Catering packages (morning and afternoon tea, lunch and evening snacks)
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="need-ccomponenets-card busi2">
                        <div className="deatils">
                            <div className="main">
                                <h1>
                                    <br />
                                    Event Space
                                </h1>
                                <hr />
                                <p  >
                                    REATIVE ZONE clients: Complimentary one hour for event space bookings (minimum of 3 hours booked).
                                    <br />
                                    <br />
                                    Valued at AED 299 per hour x 50 persons. Sunday to Thursday 8 am – 9 pm (For weekend bookings, minimum of 4 hours booked at AED 499/per hour).
                                    <br />
                                    <br />
                                    Catering packages (morning and afternoon tea, lunch and evening snacks)
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="need-ccomponenets-card busi3">
                        <div className="deatils">
                            <div className="main">
                                <h1>
                                    <br />
                                    Co- Working Space
                                </h1>
                                <hr />
                                <p  >
                                    CREATIVE ZONE clients: Complimentary one hour for event space bookings (minimum of 3 hours booked).
                                    <br />
                                    <br />
                                    Valued at AED 299 per hour x 50 persons. Sunday to Thursday 8 am – 9 pm (For weekend bookings, minimum of 4 hours booked at AED 499/per hour).
                                    <br />
                                    <br />
                                    Catering packages (morning and afternoon tea, lunch and evening snacks)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Packages Benifits */}
            {/* Packages Benifits */}
            {/* Packages Benifits */}

            <div className="page-benifits">
                <div className="main">
                    <div className="content">
                        <div className="heading">
                            <h2>
                                Benefits of Business Setup in
                                <br />
                                <span className='orange'>&nbsp;FUTURE INNOVATIVE&nbsp;</span> Business Hub
                            </h2>
                        </div>
                        <div className="list">
                            <p>
                                Business Hub is our unique service, providing a co-working space in the elegant Emaar Square, Downtown Dubai.
                                <br />
                                Experience the convenience of our fully equipped co-working space, with state-of-the-art amenities, high-speed internet, modern conference rooms, and comfortable workstations. Our Business Hub is more than just a workspace; it’s a dynamic community where like-minded professionals can collaborate, network, and grow their ventures. Whether a startup or an established entrepreneur, our flexible and affordable solution ensures you have the space and resources you need to succeed.
                            </p>
                        </div>
                    </div>

                    <div className="page-benifits-image">
                        <img src="../assets/png/02.png" alt="" />
                    </div>
                </div>

            </div>




            {/* Why Coose Us */}

            <div className="page-hero">
                {/* Page Advantage */}
                {/* Page Advantage */}
                {/* Page Advantage */}
                <div className="page-hero-adavntage">
                    <div className="main">
                        <div className="content">
                            <div className="heading">
                                <h1>
                                    Why Choose
                                    <span className='orange'> Us</span>
                                </h1>
                            </div>
                            <div className="list">
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>

                                        <div className="dis">
                                            <p>
                                                Work and meet clients in prestigious Dubai Downtown.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>

                                        <div className="dis">
                                            <p>
                                                Enjoy a concierge service with personalised support for each client.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>

                                        <div className="dis">
                                            <p>
                                                Avail of a wide range of business support services designed to save time and money.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>

                                        <div className="dis">
                                            <p>
                                                Take advantage of state-of-the-art modern facilities.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>

                                        <div className="dis">
                                            <p>
                                                Receive support from a dedicated and experienced team.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>

                                        <div className="dis">
                                            <p>
                                                Leverage convenient monthly payments and affordable hire rates.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>

                    </div>
                </div>

            </div>





        </>
    )
}

export default BusinessHub;
