import '../Css/Components/MasdarCity.css';
import React from 'react'
import '../Css/Components/Need.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';


const Communication = () => {


    return (
        <>
            <div className="page-hero com">
                <div className="page-hero-top">
                    <div className="main">
                        <div className="content">
                            <div className="content-logo">
                            </div>
                            <div className="heading">

                                <h1>
                                    Enhance Your Customer Support
                                    <br />
                                    <span className='orange'>FUTURE INNOVATIVE</span>
                                </h1>
                                <br />
                                <h3>
                                    Putting your client’s satisfaction
                                    <br />
                                    as our top priority
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-form">
                    <div className="main">
                        <div className="form">
                            <div className="sub" onClick={(e) => e.stopPropagation()}>
                                <form id="contactForm">
                                    <input type="text" name="name" placeholder="Full Name" />
                                    <input type="tel" name="phoneNumber" placeholder="Phone Number" />
                                    <input type="email" name="email" placeholder="Email" />
                                    <input type="text" name="subject" placeholder="Subject" />
                                    <textarea name="message" rows="4" placeholder="Message" />
                                    <input type="submit" value="SEND MESSAGE" className="btn-submit" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-bottom">
                    <div className="main">
                        <div className="content">
                            <h2>
                                FUTURE INNOVATIVE
                                <br />
                                <span className='orange'>COMMUNICATION HUB</span>
                            </h2>
                            <p>
                                <span className='orange'>
                                    <strong>
                                        O-Square is the UAE’s leading Communication and Customer Service provider.
                                    </strong>
                                </span>
                                <br />
                                <br />

                                With extensive local knowledge and a multi-ethnic team, O-Square expertly provides various communication services – from virtual receptionists to call centre support. With O-Square, set-up can be done within 30 minutes (which is UAE and TRA-compliant). The packages offered are also customizable and market competitive.
                            </p>
                        </div>
                        <div className="logo">
                            <div className="main">
                                <img src="../assets/logo/logo.png" alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className="need-ccomponenets">
                <div className="headings" >
                    <h1 >Our<strong className='orange'>&nbsp;Servies</strong></h1>
                </div>
                <div className="need-ccomponenets-main">
                    <div className="need-ccomponenets-card com1">
                        <div className="deatils">
                            <div className="main">
                                <h1>
                                    Virtual
                                    <br />Receptionist
                                </h1>
                                <hr />
                                <p  >
                                    Starts at AED 480 per month
                                    <br />
                                    <br />
                                    Sign up for an annual contract and get 13 months for the price of 12
                                    <br />
                                    <br />
                                    Landline numbers for Dubai, Sharjah and Fujairah
                                    <br />
                                    <br />
                                    Personalised call answering
                                </p>

                            </div>
                        </div>
                    </div>
                    <div className="need-ccomponenets-card com2">
                        <div className="deatils">
                            <div className="main">
                                <h1>
                                    Service
                                    <br />& Consultancy
                                </h1>
                                <hr />
                                <p  >
                                    Professionally trained team managing all of your inbound calls.
                                    <br />
                                    <br />
                                    Daily reporting on set KPI’s.
                                    <br />
                                    <br />
                                    Outsourced team saving you time and money.
                                    <br />
                                    <br />
                                    Affordable package prices
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            {/* Packages Benifits */}
            {/* Packages Benifits */}
            {/* Packages Benifits */}

            <div className="page-benifits">
                <div className="main">
                    <div className="content">
                        <div className="heading">
                            <h2>
                                About
                                <span className='orange'>&nbsp;O-Square&nbsp;</span>
                                <br />
                            </h2>
                        </div>
                        <div className="list">
                            <p>
                                O-Square is the UAE’s leading Communication and Customer Service provider, offering inbound and outbound support services that help small and medium businesses manage their consumer relationships. Since 2017, they’ve been making a difference with their CS expertise and experience, professional call centre agents and managers, and state-of-the-art software.
                            </p>
                        </div>
                    </div>

                    <div className="page-benifits-image">
                        <img src="../assets/png/05.png" alt="" />
                    </div>
                </div>

            </div>


            {/* Page Services */}
            {/* Page Services */}
            {/* Page Services */}

            <div className="page-servies com">
                <div className="bg">
                    <div className="main">
                        <div className="heading">
                            <h1>
                                Disrupt the
                                <span className='orange'>&nbsp;Status Quo</span>
                            </h1>
                        </div>
                        <div className="content">
                            <h3>
                                O-Square offers superior B2B partnership models to enhance customer support and maintain a vibrant communication channel that your clients can reach anytime. O-Square’s expert staff can speak all major languages, so you can be sure that efficient communication is achievable – regardless of who contacts your business.
                            </h3>
                        </div>
                    </div>
                </div>

            </div>





        </>
    )
}

export default Communication;