import '../Css/Components/MasdarCity.css';
import React, { useRef } from 'react';
import ClientSlider from './ClientSlider.js';
import Team from '../Components/Team.js';

let clients = [
    {
        name: "Business Package",
        disc: "The Business Package is designed to maximize flexibility for companies with specific requirements and is available to new and existing businesses.",
    },
    {
        name: "Women Entrepreneurship Package",
        disc: "The Women Entrepreneurship Package is exclusively for women entrepreneurs and is designed to drive the growth of women-led companies in the United Arab Emirates.",
    },
    {
        name: "Start-Up Package",
        disc: "The Start-Up Package is designed to support start-up ventures and foster innovation.",
    }
]
var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    responsive: [
        {
            breakpoint: 990,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 530,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}


const TaxAccount = () => {
    const arrowRef = useRef(null);
    let clientDisc = "";
    clientDisc = clients.map((item, i) => (
        <ClientSlider item={item} key={i} />
    ))
    return (
        <>
            <div className="page-hero tax">
                <div className="page-hero-top">
                    <div className="main">
                        <div className="content">
                            <div className="content-logo">
                            </div>
                            <div className="heading">

                                <h1>
                                    End-to-End <span className='orange'>Tax and Accounting</span>
                                    <br />
                                    Service and Support
                                </h1>
                                <br />
                                <h3>
                                    Accurate, efficient tax and accounting
                                    <br />
                                    support your company can rely on
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-form">
                    <div className="main">
                        <div className="form">
                            <div className="sub" onClick={(e) => e.stopPropagation()}>
                                <form id="contactForm">
                                    <input type="text" name="name" placeholder="Full Name" />
                                    <input type="tel" name="phoneNumber" placeholder="Phone Number" />
                                    <input type="email" name="email" placeholder="Email" />
                                    <input type="text" name="subject" placeholder="Subject" />
                                    <textarea name="message" rows="4" placeholder="Message" />
                                    <input type="submit" value="SEND MESSAGE" className="btn-submit" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-bottom">
                    <div className="main">
                        <div className="content">
                            <h2>
                                FUTURE INNOVATIVE
                                <br />
                                <span className='orange'>TAX & ACCOUNTING</span>
                            </h2>
                            <p>
                                FUTURE INNOVATIVE Tax & Accounting is an integral part of an extensive network of specialized business services focused on delivering professional end-to-end advice and support to business owners in the UAE. Our parent company,   FUTURE INNOVATIVE, was established in 2010 and has emerged as Dubai’s largest and most trusted company formation specialist firm, with over 75,000 successful company registrations. With a vision to surpass the company formation process,   FUTURE INNOVATIVE is committed to providing dedicated support and practical advice throughout the lifetime of our clients’ businesses. This commitment led to the launch of   FUTURE INNOVATIVE Tax & Accounting.
                            </p>
                        </div>
                        <div className="logo">
                            <div className="main">
                                <img src="../assets/logo/logo.png" alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            {/* Packages Steps */}
            {/* Packages Steps */}
            {/* Packages Steps */}



            {/* Packages Option */}
            {/* Packages Option */}
            {/* Packages Option */}

            <div className="page-option tax">
                <div className="main">
                    <div className="heading">
                        <h1>
                            Our
                            <span className='orange'> Services</span>
                        </h1>
                        <br />
                        <p>
                            Our broad range of tax and accounting services helps your business maintain sustainable growth and prepare for long-term financial stability. We help you streamline your accounting processes so you can easily send invoices, reconcile bank transactions, manage expenses, track inventory, and generate insightful reports effortlessly. Outsourcing a huge portion of your backend work helps you save money, as it will allow you to concentrate more on your business and scaling it up. Reach out to us for tailor-made solutions that fit your business needs.
                        </p>
                    </div>
                    <div className="list">
                        <ul>
                            <li>VAT Registrationy</li>
                            <li>VAT Registration Amendment</li>
                            <li>Financial & Management Consulting</li>
                            <li>Bookkeeping</li>
                            <li>VAT Return Filing</li>
                            <li>Auditing</li>
                        </ul>

                        <ul>
                            <li>Zoho Setup & Training</li>
                            <li>Accounting Review</li>
                            <li>Payroll</li>
                            <li>SVAT Consultancy</li>
                            <li>FTA Reconsideration Form</li>
                            <li>Corporate Tax</li>
                        </ul>
                    </div>
                </div>

            </div>

            {/* Hover */}

            <div className="need-ccomponenets page">
                <div className="need-ccomponenets-main">
                    <div className="need-ccomponenets-card tax1">
                        <div className="deatils">
                            <div className="main">
                                <h1>
                                    Documentation 
                                    <br />Requirements Clients
                                </h1>
                                <hr />
                                <p >
                                    Bank Statements
                                    <br />
                                    <br />
                                    Sales Invoices
                                    <br />
                                    <br />
                                    Expense Receipts
                                    <br />
                                    <br />
                                    Other Schedules
                                    <br />
                                    <br />
                                    Any and all relevant documents
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="need-ccomponenets-card tax2">
                        <div className="deatils">
                            <div className="main">
                                <h1>
                                    Summary of Monthly
                                    <br />Client Reporting
                                </h1>
                                <hr />
                                <p  >
                                    Clients who avail our Accounting and Bookkeeping services will receive a clear quotation outlining the reports that our team will prepare and submit on a monthly basis. These will include:
                                    <br />
                                    <br />

                                    Profit & Loss Account (Monthly)
                                    <br />
                                    <br />
                                    Balance Sheet
                                    <br />
                                    <br />
                                    Cash Flow Statement
                                    <br />
                                    <br />
                                    Trial Balance
                                    <br />
                                    <br />
                                    Receivables Aging Statement
                                    <br />
                                    <br />
                                    Accounts Payable Aging Statement
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="need-ccomponenets-card tax3">
                        <div className="deatils">
                            <div className="main">
                                <h1>
                                    Accounting and VAT
                                    <br />Submission Services
                                </h1>
                                <hr />
                                <p  >
                                    Prepare monthly financial statements using a cloud-based system
                                    <br />
                                    <br />
                                    VAT return filing on behalf of the company
                                    <br />
                                    <br />
                                    Set up Chart of Accounts (COA)
                                    <br />
                                    <br />
                                    Record all transactions on a monthly basis
                                    <br />
                                    <br />
                                    Maintain cash, bank and execute account reconciliations
                                   
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Team */}
            <Team />
        </>
    )
}

export default TaxAccount;
