import "../Css/Calculator.css";
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import CountryFlag from 'react-country-flag';


gsap.registerPlugin(ScrollTrigger);
export default function Calculator() {
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedOption1, setSelectedOption1] = useState("");
    const [selectedOption2, setSelectedOption2] = useState("");
    const [selectedOption3, setSelectedOption3] = useState("");
    const [subActive, setsubActive] = useState(false);
    const [isActive, setActive] = useState(false);
    const [selected, setSelected] = useState(" Choose one")
    const [isActiveCountry, setActiveCountry] = useState(false);
    const [selectedCountery, setSelectedCountry] = useState("");
    const chooseRef = useRef();


    useLayoutEffect(() => {
        let ctxChoose = gsap.context(() => {
            const choosetl1 = gsap.timeline();
            choosetl1.from(".calculator-heading-part-main-h1-d1 ", {
                y: 350,
                duration: 0.75
            });
            choosetl1.from(".calculator-heading-part-main-h1-d2 ", {
                y: 350,
                duration: 1
            }, "<");

            choosetl1.from(".calculator-heading-part-main-hr", {
                width: 0,
                duration: 1

            });

            ScrollTrigger.create({
                trigger: ".calculator-heading-part-main",
                start: "start 80%",
                animation: choosetl1,
                toggleActions: "restart none none reverse",
                // markers: false
            });



        }, chooseRef);

        return () => ctxChoose.revert();
    });



    useEffect(() => {
        setupEventListeners();
        return () => removeEventListeners();
    }, []);

    let nextButton, prevButton, submitButton, steps, formSteps, active;

    const setupEventListeners = () => {
        nextButton = document.querySelector('.btn-next');
        prevButton = document.querySelector('.btn-prev');
        submitButton = document.querySelector('.btn-submit');
        steps = document.querySelectorAll('.calculator-part-progress-step');
        formSteps = document.querySelectorAll('.calculator-part-form-step');
        active = 1;

        nextButton.addEventListener('click', handleNextClick);
        prevButton.addEventListener('click', handlePrevClick);
        submitButton.addEventListener('click', handleSubmitClick);
    };

    const removeEventListeners = () => {
        nextButton.removeEventListener('click', handleNextClick);
        prevButton.removeEventListener('click', handlePrevClick);
        submitButton.removeEventListener('click', handleSubmitClick);
    };


    const handleSubmitClick = () => {
        active = 1;
        console.log(active);
        updateProgress();
    };

    const handleNextClick = () => {
        active++;

        console.log(active);

        if (active > steps.length) {
            active = steps.length;
        }
        updateProgress();
    };

    const handlePrevClick = () => {
        active--;
        if (active < 1) {
            active = 1;
        }
        updateProgress();
    };

    const updateProgress = () => {
        steps.forEach((step, i) => {
            if (i === (active - 1)) {
                step.classList.add('active');
                formSteps[i].classList.add('active');
            } else {
                step.classList.remove('active');
                formSteps[i].classList.remove('active');
            }
        });

        if (active === 1) {
            prevButton.disabled = true;
            nextButton.disabled = false;
            submitButton.disabled = true;
        } else if (active === steps.length) {
            nextButton.disabled = true;
            submitButton.disabled = false;
        } else {
            prevButton.disabled = false;
            nextButton.disabled = false;
            submitButton.disabled = true;
        }
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleOptionChange1 = (event) => {
        setSelectedOption1(event.target.value);
    };
    const handleOptionChange2 = (event) => {
        setSelectedOption2(event.target.value);
    };
    const handleOptionChange3 = (event) => {
        setSelectedOption3(event.target.value);
    };

    const handleOptionsubActive = () => {
        setsubActive(!subActive);
    };

    const handleOptionsubClose = () => {
        setsubActive(false);
    };

    const option = [
        'Choose one',
        'Advertising',
        'Architecture',
        'Business Consultancy',
        'Design Services',
        'E-Commerce',
        'Event Management',
        'Fashion Design Consultancy',
        'Food & Beverage Trading',
        'General Trading',
        'Human Resources Consultancy',
        'Interior Design',
        'IT Consultancy',
        'Lifestyle Consultancy',
        'Management Consultancy',
        'Marketing Services',
        'Media Services',
        'Online Education',
        'Other',
        'Photography Services',
        'Production',
        'Project Management Consultancy',
        'Public Relations',
        'Publishing',
        'Recruitment Consultancy',
        'Software Development Management',
        'Sports Management',
        'Tax Consultancy',
        'Training Services',
        'Video Services',
        'Wholesale of Household Goods',
        'Others'
    ];

    const countries = [
        { value: 'AF', label: 'Afghanistan' },
        { value: 'AX', label: 'Åland Islands' },
        { value: 'AL', label: 'Albania' },
        { value: 'DZ', label: 'Algeria' },
        { value: 'AS', label: 'American Samoa' },
        { value: 'AD', label: 'Andorra' },
        { value: 'AO', label: 'Angola' },
        { value: 'AI', label: 'Anguilla' },
        { value: 'AQ', label: 'Antarctica' },
        { value: 'AG', label: 'Antigua and Barbuda' },
        { value: 'AR', label: 'Argentina' },
        { value: 'AM', label: 'Armenia' },
        { value: 'AW', label: 'Aruba' },
        { value: 'AU', label: 'Australia' },
        { value: 'AT', label: 'Austria' },
        { value: 'AZ', label: 'Azerbaijan' },
        { value: 'BS', label: 'Bahamas' },
        { value: 'BH', label: 'Bahrain' },
        { value: 'BD', label: 'Bangladesh' },
        { value: 'BB', label: 'Barbados' },
        { value: 'BY', label: 'Belarus' },
        { value: 'BE', label: 'Belgium' },
        { value: 'BZ', label: 'Belize' },
        { value: 'BJ', label: 'Benin' },
        { value: 'BM', label: 'Bermuda' },
        { value: 'BT', label: 'Bhutan' },
        { value: 'BO', label: 'Bolivia, Plurinational State of' },
        { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
        { value: 'BA', label: 'Bosnia and Herzegovina' },
        { value: 'BW', label: 'Botswana' },
        { value: 'BV', label: 'Bouvet Island' },
        { value: 'BR', label: 'Brazil' },
        { value: 'IO', label: 'British Indian Ocean Territory' },
        { value: 'BN', label: 'Brunei Darussalam' },
        { value: 'BG', label: 'Bulgaria' },
        { value: 'BF', label: 'Burkina Faso' },
        { value: 'BI', label: 'Burundi' },
        { value: 'KH', label: 'Cambodia' },
        { value: 'CM', label: 'Cameroon' },
        { value: 'CA', label: 'Canada' },
        { value: 'CV', label: 'Cape Verde' },
        { value: 'KY', label: 'Cayman Islands' },
        { value: 'CF', label: 'Central African Republic' },
        { value: 'TD', label: 'Chad' },
        { value: 'CL', label: 'Chile' },
        { value: 'CN', label: 'China' },
        { value: 'CX', label: 'Christmas Island' },
        { value: 'CC', label: 'Cocos (Keeling) Islands' },
        { value: 'CO', label: 'Colombia' },
        { value: 'KM', label: 'Comoros' },
        { value: 'CG', label: 'Congo' },
        { value: 'CD', label: 'Congo, the Democratic Republic of the' },
        { value: 'CK', label: 'Cook Islands' },
        { value: 'CR', label: 'Costa Rica' },
        { value: 'CI', label: 'Côte d\'Ivoire' },
        { value: 'HR', label: 'Croatia' },
        { value: 'CU', label: 'Cuba' },
        { value: 'CW', label: 'Curaçao' },
        { value: 'CY', label: 'Cyprus' },
        { value: 'CZ', label: 'Czech Republic' },
        { value: 'DK', label: 'Denmark' },
        { value: 'DJ', label: 'Djibouti' },
        { value: 'DM', label: 'Dominica' },
        { value: 'DO', label: 'Dominican Republic' },
        { value: 'EC', label: 'Ecuador' },
        { value: 'EG', label: 'Egypt' },
        { value: 'SV', label: 'El Salvador' },
        { value: 'GQ', label: 'Equatorial Guinea' },
        { value: 'ER', label: 'Eritrea' },
        { value: 'EE', label: 'Estonia' },
        { value: 'ET', label: 'Ethiopia' },
        { value: 'FK', label: 'Falkland Islands (Malvinas)' },
        { value: 'FO', label: 'Faroe Islands' },
        { value: 'FJ', label: 'Fiji' },
        { value: 'FI', label: 'Finland' },
        { value: 'FR', label: 'France' },
        { value: 'GF', label: 'French Guiana' },
        { value: 'PF', label: 'French Polynesia' },
        { value: 'TF', label: 'French Southern Territories' },
        { value: 'GA', label: 'Gabon' },
        { value: 'GM', label: 'Gambia' },
        { value: 'GE', label: 'Georgia' },
        { value: 'DE', label: 'Germany' },
        { value: 'GH', label: 'Ghana' },
        { value: 'GI', label: 'Gibraltar' },
        { value: 'GR', label: 'Greece' },
        { value: 'GL', label: 'Greenland' },
        { value: 'GD', label: 'Grenada' },
        { value: 'GP', label: 'Guadeloupe' },
        { value: 'GU', label: 'Guam' },
        { value: 'GT', label: 'Guatemala' },
        { value: 'GG', label: 'Guernsey' },
        { value: 'GN', label: 'Guinea' },
        { value: 'GW', label: 'Guinea-Bissau' },
        { value: 'GY', label: 'Guyana' },
        { value: 'HT', label: 'Haiti' },
        { value: 'HM', label: 'Heard Island and McDonald Mcdonald Islands' },
        { value: 'VA', label: 'Holy See (Vatican City State)' },
        { value: 'HN', label: 'Honduras' },
        { value: 'HK', label: 'Hong Kong' },
        { value: 'HU', label: 'Hungary' },
        { value: 'IS', label: 'Iceland' },
        { value: 'IN', label: 'India' },
        { value: 'ID', label: 'Indonesia' },
        { value: 'IR', label: 'Iran, Islamic Republic of' },
        { value: 'IQ', label: 'Iraq' },
        { value: 'IE', label: 'Ireland' },
        { value: 'IM', label: 'Isle of Man' },
        { value: 'IL', label: 'Israel' },
        { value: 'IT', label: 'Italy' },
        { value: 'JM', label: 'Jamaica' },
        { value: 'JP', label: 'Japan' },
        { value: 'JE', label: 'Jersey' },
        { value: 'JO', label: 'Jordan' },
        { value: 'KZ', label: 'Kazakhstan' },
        { value: 'KE', label: 'Kenya' },
        { value: 'KI', label: 'Kiribati' },
        { value: 'KP', label: 'Korea, Democratic People\'s Republic of' },
        { value: 'KR', label: 'Korea, Republic of' },
        { value: 'KW', label: 'Kuwait' },
        { value: 'KG', label: 'Kyrgyzstan' },
        { value: 'LA', label: 'Lao People\'s Democratic Republic' },
        { value: 'LV', label: 'Latvia' },
        { value: 'LB', label: 'Lebanon' },
        { value: 'LS', label: 'Lesotho' },
        { value: 'LR', label: 'Liberia' },
        { value: 'LY', label: 'Libya' },
        { value: 'LI', label: 'Liechtenstein' },
        { value: 'LT', label: 'Lithuania' },
        { value: 'LU', label: 'Luxembourg' },
        { value: 'MO', label: 'Macao' },
        { value: 'MK', label: 'Macedonia, the Former Yugoslav Republic of' },
        { value: 'MG', label: 'Madagascar' },
        { value: 'MW', label: 'Malawi' },
        { value: 'MY', label: 'Malaysia' },
        { value: 'MV', label: 'Maldives' },
        { value: 'ML', label: 'Mali' },
        { value: 'MT', label: 'Malta' },
        { value: 'MH', label: 'Marshall Islands' },
        { value: 'MQ', label: 'Martinique' },
        { value: 'MR', label: 'Mauritania' },
        { value: 'MU', label: 'Mauritius' },
        { value: 'YT', label: 'Mayotte' },
        { value: 'MX', label: 'Mexico' },
        { value: 'FM', label: 'Micronesia, Federated States of' },
        { value: 'MD', label: 'Moldova, Republic of' },
        { value: 'MC', label: 'Monaco' },
        { value: 'MN', label: 'Mongolia' },
        { value: 'ME', label: 'Montenegro' },
        { value: 'MS', label: 'Montserrat' },
        { value: 'MA', label: 'Morocco' },
        { value: 'MZ', label: 'Mozambique' },
        { value: 'MM', label: 'Myanmar' },
        { value: 'NA', label: 'Namibia' },
        { value: 'NR', label: 'Nauru' },
        { value: 'NP', label: 'Nepal' },
        { value: 'NL', label: 'Netherlands' },
        { value: 'NC', label: 'New Caledonia' },
        { value: 'NZ', label: 'New Zealand' },
        { value: 'NI', label: 'Nicaragua' },
        { value: 'NE', label: 'Niger' },
        { value: 'NG', label: 'Nigeria' },
        { value: 'NU', label: 'Niue' },
        { value: 'NF', label: 'Norfolk Island' },
        { value: 'MP', label: 'Northern Mariana Islands' },
        { value: 'NO', label: 'Norway' },
        { value: 'OM', label: 'Oman' },
        { value: 'PK', label: 'Pakistan' },
        { value: 'PW', label: 'Palau' },
        { value: 'PS', label: 'Palestine, State of' },
        { value: 'PA', label: 'Panama' },
        { value: 'PG', label: 'Papua New Guinea' },
        { value: 'PY', label: 'Paraguay' },
        { value: 'PE', label: 'Peru' },
        { value: 'PH', label: 'Philippines' },
        { value: 'PN', label: 'Pitcairn' },
        { value: 'PL', label: 'Poland' },
        { value: 'PT', label: 'Portugal' },
        { value: 'PR', label: 'Puerto Rico' },
        { value: 'QA', label: 'Qatar' },
        { value: 'RE', label: 'Réunion' },
        { value: 'RO', label: 'Romania' },
        { value: 'RU', label: 'Russian Federation' },
        { value: 'RW', label: 'Rwanda' },
        { value: 'BL', label: 'Saint Barthélemy' },
        { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha' },
        { value: 'KN', label: 'Saint Kitts and Nevis' },
        { value: 'LC', label: 'Saint Lucia' },
        { value: 'MF', label: 'Saint Martin (French part)' },
        { value: 'PM', label: 'Saint Pierre and Miquelon' },
        { value: 'VC', label: 'Saint Vincent and the Grenadines' },
        { value: 'WS', label: 'Samoa' },
        { value: 'SM', label: 'San Marino' },
        { value: 'ST', label: 'Sao Tome and Principe' },
        { value: 'SA', label: 'Saudi Arabia' },
        { value: 'SN', label: 'Senegal' },
        { value: 'RS', label: 'Serbia' },
        { value: 'SC', label: 'Seychelles' },
        { value: 'SL', label: 'Sierra Leone' },
        { value: 'SG', label: 'Singapore' },
        { value: 'SX', label: 'Sint Maarten (Dutch part)' },
        { value: 'SK', label: 'Slovakia' },
        { value: 'SI', label: 'Slovenia' },
        { value: 'SB', label: 'Solomon Islands' },
        { value: 'SO', label: 'Somalia' },
        { value: 'ZA', label: 'South Africa' },
        { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
        { value: 'SS', label: 'South Sudan' },
        { value: 'ES', label: 'Spain' },
        { value: 'LK', label: 'Sri Lanka' },
        { value: 'SD', label: 'Sudan' },
        { value: 'SR', label: 'Suriname' },
        { value: 'SJ', label: 'Svalbard and Jan Mayen' },
        { value: 'SZ', label: 'Swaziland' },
        { value: 'SE', label: 'Sweden' },
        { value: 'CH', label: 'Switzerland' },
        { value: 'SY', label: 'Syrian Arab Republic' },
        { value: 'TW', label: 'Taiwan, Province of China' },
        { value: 'TJ', label: 'Tajikistan' },
        { value: 'TZ', label: 'Tanzania, United Republic of' },
        { value: 'TH', label: 'Thailand' },
        { value: 'TL', label: 'Timor-Leste' },
        { value: 'TG', label: 'Togo' },
        { value: 'TK', label: 'Tokelau' },
        { value: 'TO', label: 'Tonga' },
        { value: 'TT', label: 'Trinidad and Tobago' },
        { value: 'TN', label: 'Tunisia' },
        { value: 'TR', label: 'Turkey' },
        { value: 'TM', label: 'Turkmenistan' },
        { value: 'TC', label: 'Turks and Caicos Islands' },
        { value: 'TV', label: 'Tuvalu' },
        { value: 'UG', label: 'Uganda' },
        { value: 'UA', label: 'Ukraine' },
        { value: 'AE', label: 'United Arab Emirates' },
        { value: 'GB', label: 'United Kingdom' },
        { value: 'US', label: 'United States' },
        { value: 'UM', label: 'United States Minor Outlying Islands' },
        { value: 'UY', label: 'Uruguay' },
        { value: 'UZ', label: 'Uzbekistan' },
        { value: 'VU', label: 'Vanuatu' },
        { value: 'VE', label: 'Venezuela, Bolivarian Republic of' },
        { value: 'VN', label: 'Viet Nam' },
        { value: 'VG', label: 'Virgin Islands, British' },
        { value: 'VI', label: 'Virgin Islands, U.S.' },
        { value: 'WF', label: 'Wallis and Futuna' },
        { value: 'EH', label: 'Western Sahara' },
        { value: 'YE', label: 'Yemen' },
        { value: 'ZM', label: 'Zambia' },
        { value: 'ZW', label: 'Zimbabwe' },
    ]



    return (
        <>
            <div ref={chooseRef} className="calculator-heading-part">
                <div className="calculator-heading-part-main">
                    <h1 className="calculator-heading-part-main-h1-d1 display2">Estimate Your Business</h1>
                    <h1 className="calculator-heading-part-main-h1-d2 display4">Startup Expenses Today</h1>
                    <hr className="calculator-heading-part-main-hr" />
                </div>
            </div>
            <div className="calculator-part">
                <div className="calculator-part-form-box">
                    <div className="calculator-part-progress">
                        <div className="calculator-part-progress-logo">
                            <div className="calculator-part-progress-logo-images">
                                <img src="../assets/logo/logo.png" alt="" />
                            </div>
                            <h1 className="orange display4">FUTURE INNOVATIVE</h1>
                            <p className="lead">Expenses Calculator</p>
                        </div>
                        <div className="calculator-part-progress-steps">
                            <div className="calculator-part-progress-step active">
                                <div className="step-number">
                                    <p className="lead">
                                        1
                                    </p>
                                </div>
                                <p className="lead">
                                    Business Details
                                </p>
                            </div>
                            <div className="calculator-part-progress-step">
                                <div className="step-number">
                                    <p className="lead">
                                        2
                                    </p>
                                </div>
                                <p className="lead">
                                    Company Information
                                </p>
                            </div>
                            <div className="calculator-part-progress-step">
                                <div className="step-number">
                                    <p className="lead">
                                        3
                                    </p>
                                </div>
                                <p className="lead">
                                    Company Information
                                </p>
                            </div>
                            <div className="calculator-part-progress-step">
                                <div className="step-number">
                                    <p className="lead">
                                        4
                                    </p>
                                </div>
                                <p className="lead">
                                    Company Information
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="calculator-part-form">
                        <form action="">
                            <div className="form-one calculator-part-form-step  active" >
                                <h2>
                                    Business Details
                                </h2>
                                <hr />
                                <div className="calculator-part-form-step-inner">
                                    <p className="form">
                                        Which business activity are you seeking?
                                    </p>
                                    <div className="dropdown">
                                        <div className={`dropdown-btn ${isActive ? 'active' : ''}`} onClick={(e) => setActive(!isActive)}>
                                            <p >
                                                {selected}
                                            </p>
                                        </div>
                                        {
                                            isActive && (
                                                <div className="dropdown-content">
                                                    {
                                                        option.map((option) => (
                                                            <div
                                                                onClick={(e) => {
                                                                    setSelected(option);
                                                                    setActive(false)
                                                                }}
                                                                className="dropdown-item">
                                                                <p>
                                                                    {option}
                                                                </p>
                                                            </div>
                                                        )
                                                        )}
                                                </div>
                                            )
                                        }
                                    </div>

                                    {/* Authority */}
                                    {/* Authority */}
                                    {/* Authority */}
                                    <p className="form">
                                        Choose a jurisdiction authority
                                    </p>
                                    <div className='checked-box-options'>
                                        <div className="checked-box-menu">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="defult"
                                                    checked={subActive}
                                                    onChange={(e) => {
                                                        handleOptionChange(e);
                                                        handleOptionsubActive(e);
                                                    }}
                                                />
                                                <span></span>
                                            </label>
                                            <p>Dubai Mainland</p>
                                        </div>

                                        {/* Sub Menu */}
                                        {/* Sub Menu */}
                                        {/* Sub Menu */}

                                        {subActive && (
                                            <div className='checked-box-options sub'>
                                                <p className="form">
                                                    Do You require sponsorship?
                                                </p>
                                                <div className="checked-box-menu">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            value="Dubai Mainland with sponsorship"
                                                            checked={selectedOption === "Dubai Mainland with sponsorship"}
                                                            onChange={(e) => {
                                                                handleOptionChange(e);
                                                            }}
                                                        />
                                                        <span></span>
                                                    </label>
                                                    <p>Yes</p>
                                                </div>

                                                <div className="checked-box-menu">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            value="Dubai Mainland with out sponsorship"
                                                            checked={selectedOption === "Dubai Mainland with out sponsorship"}
                                                            onChange={(e) => {
                                                                handleOptionChange(e);
                                                            }}
                                                        />
                                                        <span></span>
                                                    </label>
                                                    <p>No</p>
                                                </div>
                                            </div>
                                        )}


                                        {/* Sub Menu */}
                                        {/* Sub Menu */}
                                        {/* Sub Menu */}

                                        <div className="checked-box-menu">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="Free Zone"
                                                    checked={selectedOption === "Free Zone"}
                                                    onChange={(e) => {
                                                        handleOptionChange(e);
                                                        handleOptionsubClose(e)
                                                    }}
                                                />
                                                <span></span>
                                            </label>
                                            <p>Free Zone</p>
                                        </div>
                                        <div className="checked-box-menu">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="Others"
                                                    checked={selectedOption === "Others"}
                                                    onChange={(e) => {
                                                        handleOptionChange(e);
                                                        handleOptionsubClose(e)
                                                    }}
                                                />
                                                <span></span>
                                            </label>
                                            <p>Others</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-two calculator-part-form-step">
                                <h2>
                                    Contact Information
                                </h2>
                                <hr />
                                {/* Patnertrs */}
                                {/* Patnertrs */}
                                {/* Patnertrs */}

                                <div className='calculator-part-form-step-inner'>
                                    <p className="form">
                                        How Many Owners will be involved?
                                    </p>
                                    <div className="checked-box-options">
                                        <div className="checked-box-menu">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="Single"
                                                    checked={selectedOption1 === "Single"}
                                                    onChange={handleOptionChange1}
                                                />
                                                <span></span>
                                            </label>
                                            <p>Single</p>
                                        </div>
                                        <div className="checked-box-menu">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="Multiple"
                                                    checked={selectedOption1 === "Multiple"}
                                                    onChange={handleOptionChange1}
                                                />
                                                <span></span>
                                            </label>
                                            <p>Multiple</p>
                                        </div>
                                        <div className="checked-box-menu">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="Not Sure"
                                                    checked={selectedOption1 === "Not Sure"}
                                                    onChange={handleOptionChange1}
                                                />
                                                <span></span>
                                            </label>
                                            <p>Not Sure</p>
                                        </div>
                                    </div>
                                    {/* Visa */}
                                    {/* Visa */}
                                    {/* Visa */}
                                    <p className="form">
                                        Will you require Visa(s)?
                                    </p>
                                    <div className='checked-box-options'>
                                        <div className="checked-box-menu">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="Visa Yes"
                                                    checked={selectedOption2 === "Visa Yes"}
                                                    onChange={handleOptionChange2}
                                                />
                                                <span></span>
                                            </label>
                                            <p>Yes</p>
                                        </div>
                                        <div className="checked-box-menu">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="No Visa"
                                                    checked={selectedOption2 === "No Visa"}
                                                    onChange={handleOptionChange2}
                                                />
                                                <span></span>
                                            </label>
                                            <p>No</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="form-three calculator-part-form-step">
                                <h2>
                                    Busines Name
                                </h2>
                                <hr />
                                <div className="calculator-part-form-step-inner">
                                    <p className="form">
                                        Do you have a business name in mind?
                                    </p>
                                    <input type="text" placeholder="AEC Comapny" />
                                    <p className="form">
                                        Do you require an office Space?
                                    </p>
                                    <div className='checked-box-options'>
                                        <div className="checked-box-menu">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="Yes"
                                                    checked={selectedOption3 === "Yes"}
                                                    onChange={handleOptionChange3}
                                                />
                                                <span></span>
                                            </label>
                                            <p>Yes</p>
                                        </div>
                                        <div className="checked-box-menu">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="No"
                                                    checked={selectedOption3 === "No"}
                                                    onChange={handleOptionChange3}
                                                />
                                                <span></span>
                                            </label>
                                            <p>No</p>
                                        </div>
                                        <div className="checked-box-menu">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="Not Sure"
                                                    checked={selectedOption3 === "Not Sure"}
                                                    onChange={handleOptionChange3}
                                                />
                                                <span></span>
                                            </label>
                                            <p>Not Sure</p>
                                        </div>

                                    </div>
                                </div>




                            </div>
                            <div className="form-four calculator-part-form-step">
                                <h2>
                                    Conatact Deails
                                </h2>
                                <hr />
                                <div className="calculator-part-form-step-inner">
                                    <input type="tel" placeholder="Eg: Mark Jhone" />
                                    <input type="tel" placeholder="+971500000000" />
                                    <input type="text" placeholder="Street Address" />
                                    <div className="dropdown">
                                        <div className="dropdown-btn" onClick={(e) => setActiveCountry(!isActiveCountry)}>
                                            <p>{countries.label}</p>
                                        </div>
                                        {
                                            isActiveCountry && (
                                                <div className="dropdown-content">
                                                    {
                                                        countries.map((countries) => (
                                                            <div
                                                                onClick={(e) => {
                                                                    setSelectedCountry(countries);
                                                                    setActiveCountry(false)
                                                                }}
                                                                className="dropdown-item">
                                                                <CountryFlag countryCode={countries.value} svg /> {/* Renders country flag */}
                                                                <p>{countries.label}</p>
                                                            </div>
                                                        )
                                                        )}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="btn-group">
                                <button type="button" className="btn-prev" disabled > Back </button>
                                <button type="button" className="btn-next"> Next </button>
                                <button type="button" className="btn-submit" disabled> Submit </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </>

    );

}
