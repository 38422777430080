import React from 'react';
import '../Css/ContactUs.css';

export default function ContactUs() {
    return (
        <>
            <div className="contactus">
                <div className="bg-dubai">
                    <img src="../assets/contactus/dubai.png" alt="" />
                </div>
                <div className="border">

                </div>
                <div className="main">
                    <div className="content">
                        <h1>
                            LET'S GET
                            <br />
                            <span>
                                START
                            </span>
                        </h1>
                        <br />
                        <p>
                            Creative Zone’s mission is to be a one-stop company for all your business needs.
                            <br />
                            <br />
                            Over the last 13 years, our team has helped over 75,000 startups and SMEs expand and grow their business.
                            <br />
                            <br />
                            We offer tailored solutions to help your business from pre-setup to growth.
                            <br />
                            <br />
                            Contact us to begin your successful business journey in the UAE.
                            Over the last 13 years, our team has helped over 75,000 startups and SMEs expand and grow their business.
                            <br />
                            <br />
                            We offer tailored solutions to help your business from pre-setup to growth.
                            <br />
                            <br />
                            Contact us to begin your successful business journey in the UAE.
                        </p>
                    </div>
                    <div className="form">
                        <div className="sub" onClick={(e) => e.stopPropagation()}>
                            <form id="contactForm">
                                <input type="text" name="name" placeholder="Full Name" />
                                <input type="tel" name="phoneNumber" placeholder="Phone Number" />
                                <input type="email" name="email" placeholder="Email" />
                                <input type="text" name="subject" placeholder="Subject" />
                                <textarea name="message" rows="4" placeholder="Message" />
                                <input type="submit" value="SEND MESSAGE" className="btn-submit" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
