import '../Css/Components/MasdarCity.css';
import React, { useRef } from 'react';
import Slider from 'react-slick'
import ClientSlider from './ClientSlider.js';
import { RiH2 } from 'react-icons/ri';

let clients = [
    {
        name: "Business Package",
        disc: "The Business Package is designed to maximize flexibility for companies with specific requirements and is available to new and existing businesses.",
    },
    {
        name: "Women Entrepreneurship Package",
        disc: "The Women Entrepreneurship Package is exclusively for women entrepreneurs and is designed to drive the growth of women-led companies in the United Arab Emirates.",
    },
    {
        name: "Start-Up Package",
        disc: "The Start-Up Package is designed to support start-up ventures and foster innovation.",
    }
]
var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    responsive: [
        {
            breakpoint: 990,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 530,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}


const MasdarCity = () => {
    const arrowRef = useRef(null);
    let clientDisc = "";
    clientDisc = clients.map((item, i) => (
        <ClientSlider item={item} key={i} />
    ))
    return (
        <>
            <div className="page-hero">
                <div className="page-hero-top">
                    <div className="main">
                        <div className="content">
                            <div className="content-logo">
                                <img src="../assets/masdar-city/logo.svg" alt="" />
                            </div>
                            <div className="heading">
                                <h2>
                                    Set Up Your Business in
                                </h2>
                                <h1 className='orange'>
                                    Masdar City Free Zone
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-form">
                    <div className="main">
                        <div className="form">
                            <div className="sub" onClick={(e) => e.stopPropagation()}>
                                <form id="contactForm">
                                    <input type="text" name="name" placeholder="Full Name" />
                                    <input type="tel" name="phoneNumber" placeholder="Phone Number" />
                                    <input type="email" name="email" placeholder="Email" />
                                    <input type="text" name="subject" placeholder="Subject" />
                                    <textarea name="message" rows="4" placeholder="Message" />
                                    <input type="submit" value="SEND MESSAGE" className="btn-submit" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-bottom">
                    <div className="main">
                        <div className="content">
                            <h2>
                                About
                                <span className='orange'>&nbsp;Masdar&nbsp;City</span>
                            </h2>
                            <p>
                                Masdar City stands out as a vibrant business centre and represents one of the leading global sustainable city projects in Abu Dhabi. Masdar City has cultivated an innovative ecosystem encompassing innovation, education, research and development, technology, and commerce. Hosting over 1,000 entities, ranging from emerging startups and small to medium-sized enterprises (SMEs) to large multinational corporations, Masdar City operates in critical areas, including renewable energy, artificial intelligence, mobility, energy storage, water conservation, healthcare, agriculture, space exploration, and sustainable city planning.
                            </p>
                            <p>
                                The economic free zone within Masdar City offers a crucial strategic location and networking platform. It enables businesses to establish local and international partnerships, explore various investment opportunities, and test innovative technologies. Moreover, Masdar City boasts a residential area dedicated to leisure and wellness, enhancing its inhabitants’ quality of life.
                            </p>
                        </div>
                        <div className="logo">
                            <div className="main">
                                <img src="../assets/logo/logo.png" alt="" />
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            {/* Page Packages */}
            {/* Page Packages */}
            {/* Page Packages */}

            <div className="page-packages">
                <div className="bg">
                    <div className="main">
                        <div className="heading">
                            <h1>
                                Explore Future Innovative
                                <br />
                                <span className='orange'> All-Inclusive Package </span>in Masdar City Free Zone
                            </h1>
                        </div>
                        <div className="price">
                            <h2>Package Starting from <span className='orange'> AED 24,360</span></h2>
                        </div>
                        <div className="content">
                            <p>
                                Masdar City offers comprehensive business support services, including fast licensing, residence visas for staff, and affordable leasing options for shared workspaces, executive suites, and larger corporate needs.
                            </p>
                        </div>
                        <div className="list">
                            <div className="item">
                                <div className="main">
                                    <img src="../assets/masdar-city/01.png" alt="" />
                                </div>
                                <div className="text">
                                    <h3>
                                        Business <br />
                                        Trade License
                                    </h3>
                                </div>
                            </div>
                            <div className="item">
                                <div className="main">
                                    <img src="../assets/masdar-city/02.png" alt="" />
                                </div>
                                <div className="text">
                                    <h3>
                                        Investor/ <br />
                                        Residency Visa
                                    </h3>
                                </div>
                            </div>
                            <div className="item">
                                <div className="main">
                                    <img src="../assets/masdar-city/03.png" alt="" />
                                </div>
                                <div className="text">
                                    <h3>
                                        Up to 5 Business <br />
                                        Activities
                                    </h3>
                                </div>

                            </div>
                            <div className="item">
                                <div className="main">
                                    <img src="../assets/masdar-city/04.png" alt="" />
                                </div>
                                <div className="text">
                                    <h3>
                                        Registration <br />
                                        Fees
                                    </h3>
                                </div>
                            </div>
                            <div className="item">
                                <div className="main">
                                    <img src="../assets/masdar-city/05.png" alt="" />
                                </div>
                                <div className="text">
                                    <h3>
                                        Immigration <br />
                                        Establishment Card
                                    </h3>
                                </div>
                            </div>
                            <div className="item">
                                <div className="main">
                                    <img src="../assets/masdar-city/06.png" alt="" />
                                </div>
                                <div className="text">
                                    <h3>
                                        E-channel <br />
                                        Registration
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Page Package Zoon Slider */}
                {/* Page Package Zoon Slider */}
                {/* Page Package Zoon Slider */}

                <div className="package-zoon-slider">
                    <div className="bg-dubai">
                        <img src="../assets/contactus/dubai.png" alt="" />
                    </div>
                    <div className="main">
                        <div className="content">
                            <div className="heading">
                                <h1>
                                    Masdar Free Zone
                                    <span className='orange'> Packages</span>
                                </h1>
                            </div>
                            <div className="slide">
                                <Slider ref={arrowRef} {...settings}>
                                    {clientDisc}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Packages Steps */}
            {/* Packages Steps */}
            {/* Packages Steps */}

            <div className="packages-steps">
                <div className="main">
                    <div className="sticky">
                        <div className="heading">
                            <h2>
                                Steps for <br />
                                Company Formation in
                            </h2>
                            <h1 className='orange'>
                                Masdar City Free Zone
                            </h1>

                            <div className="dis">
                                <p>
                                    Setting up a company can sometimes seem challenging, but Future Innovative is here to simplify your company formation and save you time and money.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="move">
                        <div className="list">
                            <div className="item">
                                <div className="number">
                                    <h1>1</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Select Your Legal Structure
                                    </h2>
                                    <hr />
                                    <p>
                                        Masdar City Free Zone provides various legal structures, including a Free Zone Limited Liability Company (LLC), a branch of a foreign or offshore company, and a branch of a UAE-based company. Consult with an advisor from Future Innovative to determine the legal structure best suited for your business needs. After selecting the right legal structure, you will move forward with the incorporation process.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="number">
                                    <h1>2</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Reserve Your Company Name
                                    </h2>
                                    <hr />
                                    <p>
                                        At this point, you will select an appropriate name for your company. Future Innovative will assist you in submitting your initial application and securing the necessary approval from the Abu Dhabi Department of Economic Development for your chosen name reservation.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="number">
                                    <h1>3</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Submit Your Documents
                                    </h2>
                                    <hr />
                                    <p>
                                        Following the reservation of your company name, you must submit all necessary documents to obtain your company’s legal documents, such as the Resolution, Articles of Association, or Memorandum of Association. Our expert team will support you during the document submission process.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="number">
                                    <h1>4</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Finalize the Lease Process
                                    </h2>
                                    <hr />
                                    <p>
                                        Masdar City leases office spaces suited to various needs of businesses of all sizes, including start-ups, SMEs, and multinationals. The free zone offers a range of lease options, such as virtual desks, flexi-desks in shared workspaces, serviced and executive offices, and larger core and shell units. Once the legal documents are signed and the lease option is finalized, you will proceed to the payment stage. Future Innovative will guide you through this process, making it seamless and efficient.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="number">
                                    <h1>5</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Receive Your Business License
                                    </h2>
                                    <hr />
                                    <p>
                                        Ultimately, you will obtain your license and certificate of registration/incorporation, accessible via the Masdar Free Zone’s online platform. Future Innovative will provide support throughout the company formation process, ensuring that your business setup journey is streamlined and hassle-free.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Packages Option */}
            {/* Packages Option */}
            {/* Packages Option */}

            <div className="page-option">
                <div className="main">
                    <div className="heading">
                        <h1>
                            License Options Offered
                            <br />
                            <span className='orange'> by Masdar City Free Zone</span>
                        </h1>
                    </div>
                    <div className="list">
                        <ul>
                            <li>Renewable energy</li>
                            <li>Cleantech & information & communication tech</li>
                            <li>Marketing and events</li>
                            <li>Human Resources</li>
                            <li>Energy</li>
                            <li>Healthcare Services</li>
                            <li>Life Sciences</li>
                        </ul>

                        <ul>
                            <li>Service Providers</li>
                            <li>Property Development Services</li>
                            <li>Regional HQ trading and holding companies</li>
                            <li>Business centre or data centre</li>
                            <li>Oil and Gas Services</li>
                            <li>Retail, Food, and Beverage</li>
                        </ul>
                    </div>
                </div>

            </div>

            {/* Packages Benifits */}
            {/* Packages Benifits */}
            {/* Packages Benifits */}

            <div className="page-benifits">
                <div className="main">

                    <div className="content">
                        <div className="heading">
                            <h2>
                                Benefits of Business Setup in
                                <br />
                                <span className='orange'> by Masdar City Free Zone</span>
                            </h2>
                        </div>
                        <div className="list">
                            <ul>
                                <li>100% Foreign Ownership</li>
                                <li>100% Exemption from Personal Income Tax</li>
                                <li>0% Import Tariffs</li>
                                <li>Freedom to Repatriate Capital and Profits</li>
                                <li>Dual Licensing and Office Space</li>
                                <li>A Launch Point into Local, Regional and International Markets</li>
                                <li>One-Stop-Shop for registration, government relations and visa processing</li>
                                <li>Reduced Tax Footprint</li>
                                <li>Professional Access to R&D partnerships</li>
                            </ul>
                        </div>
                    </div>

                    <div className="page-benifits-image">
                        <img src="../assets/masdar-city/benifits.png" alt="" />
                    </div>
                </div>

            </div>




        </>
    )
}

export default MasdarCity;
