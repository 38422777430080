import '../Css/Components/MasdarCity.css';
import React, { useRef } from 'react';
import Slider from 'react-slick'
import ClientSlider from './ClientSlider.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

let clients = [
    {
        name: "Business Package",
        disc: "The Business Package is designed to maximize flexibility for companies with specific requirements and is available to new and existing businesses.",
    },
    {
        name: "Women Entrepreneurship Package",
        disc: "The Women Entrepreneurship Package is exclusively for women entrepreneurs and is designed to drive the growth of women-led companies in the United Arab Emirates.",
    },
    {
        name: "Start-Up Package",
        disc: "The Start-Up Package is designed to support start-up ventures and foster innovation.",
    }
]
var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    responsive: [
        {
            breakpoint: 990,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 530,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}


const Dmmc = () => {
    const arrowRef = useRef(null);
    let clientDisc = "";
    clientDisc = clients.map((item, i) => (
        <ClientSlider item={item} key={i} />
    ))
    return (
        <>
            <div className="page-hero dmmc">
                <div className="page-hero-top">
                    <div className="main">
                        <div className="content">
                            <div className="content-logo">
                                <img src="../assets/dmmc/logo.webp" alt="" />
                            </div>
                            <div className="heading">
                                <h2>
                                    Set Up Your Business in
                                </h2>
                                <h1 className='orange'>
                                    DMMC
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-form">
                    <div className="main">
                        <div className="form">
                            <div className="sub" onClick={(e) => e.stopPropagation()}>
                                <form id="contactForm">
                                    <input type="text" name="name" placeholder="Full Name" />
                                    <input type="tel" name="phoneNumber" placeholder="Phone Number" />
                                    <input type="email" name="email" placeholder="Email" />
                                    <input type="text" name="subject" placeholder="Subject" />
                                    <textarea name="message" rows="4" placeholder="Message" />
                                    <input type="submit" value="SEND MESSAGE" className="btn-submit" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-bottom">
                    <div className="main">
                        <div className="content">
                            <h2>
                                About
                                <span className='orange'>&nbsp;DMMC</span>
                            </h2>
                            <p>
                                The Dubai Multi Commodities Center (DMCC) is one of the largest free zone and trade hubs in Dubai, United Arab Emirates. Established in 2002, DMCC offers a conducive business environment that comes with a wide range of support services for companies, such as access to trade finance, market intelligence, and networking opportunities.
                            </p>
                            <p>
                                Over the years, DMCC has cemented its position as one of the most successful free zones in the UAE, attracting over 15,000 companies from across the world. DMCC is known for its pro-business policies and support for startups, SMEs, and the commodities sector.
                            </p>

                        </div>
                        <div className="logo">
                            <div className="main">
                                <img src="../assets/logo/logo.png" alt="" />
                            </div>
                        </div>

                    </div>
                </div>

                {/* Page Advantage */}
                {/* Page Advantage */}
                {/* Page Advantage */}
                <div className="page-hero-adavntage">
                    <div className="main">
                        <div className="content">
                            <div className="heading">
                                <h1>
                                    Advantages of Setting Up Your Business in
                                    <span className='orange'>&nbsp;DMMC</span>
                                </h1>
                                <p>
                                    The Dubai Multi Commodities Center (DMCC) is one of the largest free zone and trade hubs in Dubai, United Arab Emirates. Established in 2002, DMCC offers a conducive business environment that comes with a wide range of support services for companies, such as access to trade finance, market intelligence, and networking opportunities.
                                </p>
                                <p>
                                    Over the years, DMCC has cemented its position as one of the most successful free zones in the UAE, attracting over 15,000 companies from across the world. DMCC is known for its pro-business policies and support for startups, SMEs, and the commodities sector.
                                </p>
                            </div>
                            <div className="list">
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>
                                        <div className="heading">
                                            <h3>
                                                Access to Global Market
                                            </h3>
                                        </div>
                                        <div className="dis">
                                            <ul>
                                                <li>
                                                    DMCC is located in the heart of Dubai, with close proximity to airports, and seaports.
                                                </li>
                                                <li>
                                                    Dubai is home to many expats. In fact, about 90% of the city’s population is composed of foreigners, giving you the opportunity to conduct business with multinationals.
                                                </li>
                                                <li>
                                                    Effective immigration services, including visa support.
                                                </li>
                                            </ul>
                                        </div>

                                    </div>

                                </div>
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>
                                        <div className="heading">
                                            <h3>
                                                One-Stop Shop
                                            </h3>
                                        </div>
                                        <div className="dis">
                                            <ul>
                                                <li>
                                                    DMCC is a one-stop shop for all business needs. It offers facilities and access to a world-class market, secure storage, trading and research services, world-class legal and financial advice firms, and an unrivaled logistics and transportation system.
                                                </li>
                                                <li>
                                                    With its diverse range of services and facilities, DMCC provides a member online portal to ensure a rapid response to any requests.
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>
                                        <div className="heading">
                                            <h3>
                                                Tax and Financial Benefits
                                            </h3>
                                        </div>
                                        <div className="dis">
                                            <ul>
                                                <li>
                                                    100% foreign company ownership.
                                                </li>
                                                <li>
                                                    No restrictions on capital repatriation.
                                                </li>
                                                <li>
                                                    No currency restrictions.
                                                </li>
                                                <li>
                                                    No personal income tax.
                                                </li>
                                                <li>
                                                    A 5% standard VAT rate.
                                                </li>
                                                <li>
                                                    Only 9% corporate income tax (if applicable).
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>

            </div>

            {/* Page Packages */}
            {/* Page Packages */}
            {/* Page Packages */}

            <div className="page-packages dmmc">
                <div className="bg">
                    <div className="main">
                        <div className="heading">
                            <h1>
                                Explore Future Innovative
                                <br />
                                <span className='orange'> All-Inclusive Package </span>in DMCC
                            </h1>
                        </div>

                        <div className="price">
                            <h2>Package Starting from <span className='orange'> AED 24,000</span></h2>
                        </div>
                        <div className="content">
                            <p>
                                The cost of setting up a business in DMCC will depend on different factors, including the number of visas you need for your company and office lease.
                            </p>
                        </div>
                        <div className="list">
                            <div className="item">
                                <div className="main">
                                    <img src="../assets/masdar-city/01.png" alt="" />
                                </div>
                                <div className="text">
                                    <h3>
                                        Business <br />
                                        Trade License
                                    </h3>
                                </div>
                            </div>
                            <div className="item">
                                <div className="main">
                                    <img src="../assets/masdar-city/02.png" alt="" />
                                </div>
                                <div className="text">
                                    <h3>
                                        Investor/ <br />
                                        Residency Visa
                                    </h3>
                                </div>
                            </div>
                            <div className="item">
                                <div className="main">
                                    <img src="../assets/masdar-city/03.png" alt="" />
                                </div>
                                <div className="text">
                                    <h3>
                                        Up to 5 Business <br />
                                        Activities
                                    </h3>
                                </div>

                            </div>
                            <div className="item">
                                <div className="main">
                                    <img src="../assets/masdar-city/04.png" alt="" />
                                </div>
                                <div className="text">
                                    <h3>
                                        Registration <br />
                                        Fees
                                    </h3>
                                </div>
                            </div>
                            <div className="item">
                                <div className="main">
                                    <img src="../assets/masdar-city/05.png" alt="" />
                                </div>
                                <div className="text">
                                    <h3>
                                        Immigration <br />
                                        Establishment Card
                                    </h3>
                                </div>
                            </div>
                            <div className="item">
                                <div className="main">
                                    <img src="../assets/masdar-city/06.png" alt="" />
                                </div>
                                <div className="text">
                                    <h3>
                                        E-channel <br />
                                        Registration
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Page Package Zoon Slider */}
                {/* Page Package Zoon Slider */}
                {/* Page Package Zoon Slider */}

                <div className="package-zoon-slider">
                    <div className="bg-dubai">
                        <img src="../assets/contactus/dubai.png" alt="" />
                    </div>
                    <div className="main">
                        <div className="content">
                            <div className="heading">
                                <h1>
                                    Business Structures <br />
                                    in<span className='orange'> DMCC</span>
                                </h1>
                                <p>
                                    Here are the following legal company structures from which you can choose to establish your company in DMCC:
                                </p>
                            </div>
                            <div className="slide">
                                <Slider ref={arrowRef} {...settings}>
                                    {clientDisc}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Packages Steps */}
            {/* Packages Steps */}
            {/* Packages Steps */}

            <div className="packages-steps">
                <div className="main">
                    <div className="sticky">
                        <div className="heading">

                            <h1 >        Steps in Setting Up Your
                                <br />
                                Business in <span className='orange'> DMCC</span>
                            </h1>

                            <div className="dis">
                                <p>
                                    Company setup process in DMCC usually takes around 2-4 weeks depending on your business activity.  </p>
                            </div>
                        </div>
                    </div>
                    <div className="move">
                        <div className="list">
                            <div className="item">
                                <div className="number">
                                    <h1>1</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Choose your business activitie
                                    </h2>
                                    <hr />
                                    <p>
                                        The first thing to do in incorporating your business in DMCC is to outline your business activities to evaluate what type of trade license you will get
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="number">
                                    <h1>2</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Apply online
                                    </h2>
                                    <hr />
                                    <p>
                                        Fill out your application form correctly and submit it online. DMCC will complete the company name reservation, management, and compliance approval
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="number">
                                    <h1>3</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Pay, sign & submit the relevant documents
                                    </h2>
                                    <hr />
                                    <p>
                                        After submission of your initial application, you can now pay for the registration and license fees and sign the legal documents.
                                        <br />
                                        You will be then granted by DMCC a provisional approval and bank letter to open a company bank account.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="number">
                                    <h1>4</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Choose the most suitable office space
                                    </h2>
                                    <hr />
                                    <p>
                                        Select an office space that can accommodate your staff. Creative Zone can help you in selecting the most suitable and accessible location for your company.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="number">
                                    <h1>5</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Secure your visas
                                    </h2>
                                    <hr />
                                    <p>
                                        The next crucial step will be to secure DMCC visas for your team, so you can begin your operations. We will be happy to assist you with the needed documents and the application process.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Packages Option */}
            {/* Packages Option */}
            {/* Packages Option */}

            <div className="page-option dmmc">
                <div className="main">
                    <div className="heading">
                        <h1>
                            Documents for
                            <br />
                            <span className='orange'> DMCC </span> Company Setup
                        </h1>
                    </div>
                    <div className="list">
                        <ul>
                            <li>Online pre-approval application form</li>
                            <li>Business profile</li>
                            <li>Third-party approvals for specific regulated business activities</li>
                            <li>Copy of your passport (Along with your UAE residence visa and a copy of your Emirates’ ID, if applicable)</li>
                            <li>Proof of residential address in country of residence (A utility bill or bank statement)</li>
                        </ul>

                        <ul>
                            <li>Business plan (depends on activities)</li>
                            <li>Details of an office address in DMCC free zone (JLT)</li>
                            <li>Copies of parent company documents if it’s a branch company formation. (Required after the company has been registered)</li>
                            <li>Power of Attorney for Manager, Secretary, Director, Specimen Signature</li>
                            <li>No Objection Certificate (NOC) from the current sponsor if you already have a residence visa</li>
                        </ul>
                    </div>
                </div>

            </div>

    




        </>
    )
}

export default Dmmc;
