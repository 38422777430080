import React from 'react'
import '../Css/Team.css';
import { MdOutlineEmail, MdCall , MdWhatsapp } from "react-icons/md";

export default function Team() {
    return (
        <>
            <div className="team">
                <div className="main">
                    <h1 className='display2 orange'> OUR TEAM</h1>
                    <h1 className='display4'>
                            Book a Free Consultation
                    </h1>
                    <hr />
                    <div className="list">
                        <div className="iteam">
                            <img src="../assets/team/01.webp" alt="" />
                            <div className="details">
                                <h3>Prashanth Salyan</h3>
                                <p>CEO</p>
                            </div>
                            <div className="logo">
                                <MdCall className='icon'/>
                                <MdOutlineEmail className='icon'/>
                                <MdWhatsapp className='icon'/>
                            </div>
                        </div>
                  
                        <div className="iteam">
                            <img src="../assets/team/03.webp" alt="" />
                            <div className="details">
                                <h3>CHRYSANN FERRO</h3>
                                <p>MANAGER</p>
                            </div>
                            <div className="logo">
                                <MdCall className='icon'/>
                                <MdOutlineEmail className='icon'/>
                                <MdWhatsapp className='icon'/>
                            </div>
                        </div>
                        {/* <div className="iteam">
                            <img src="../assets/team/04.webp" alt="" />
                            <div className="details">
                                <h3>SANA</h3>
                                <p>MANAGER</p>
                            </div>
                            <div className="logo">
                                <MdCall className='icon'/>
                                <MdOutlineEmail className='icon'/>
                                <MdWhatsapp className='icon'/>
                            </div>
                        </div> */}
                        <div className="iteam">
                            <img src="../assets/team/02.webp" alt="" />
                            <div className="details">
                                <h3>NITIN SHETTY</h3>
                                <p>MANAGER</p>
                            </div>
                            <div className="logo">
                                <MdCall className='icon'/>
                                <MdOutlineEmail className='icon'/>
                                <MdWhatsapp className='icon'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
