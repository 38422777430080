import "../Css/Why.css"
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef } from 'react';

gsap.registerPlugin(ScrollTrigger);

export default function Why() {
    const whyChooseRef = useRef();


    useLayoutEffect(() => {
        let ctxWhyChoose = gsap.context(() => {
            const whychoosetl1 = gsap.timeline();
            whychoosetl1.from(".why-choose-heading-h1", {
                y: 350,
                letterSpacing: "20px",
                duration: 0.5
            });

            whychoosetl1.from(".why-choose-hr", {
                width: 0,
                duration: 0.5

            });
            // whychoosetl1.from(".why-choose-us-moving", {
            //     y: 350,

            // }, "<");

         

            ScrollTrigger.create({
                trigger: ".why-choose-us-main",
                start: "start 80%",
                animation: whychoosetl1,
                toggleActions: "restart none none reverse",
                //markers: false
            });
        }, whyChooseRef);

        return () => ctxWhyChoose.revert();
    });

    useLayoutEffect(() => {
        const ctxWhyChoose = gsap.context(() => {
            const handleMouseEnter = (chooseItemRef) => {
                const mouseEntertl = gsap.timeline();
                mouseEntertl.to(chooseItemRef.querySelector(".why-choose-us-moving-item-background-images img"), {
                    scale: 1.3,
                    ease: "power1.in"
                });
                mouseEntertl.to(chooseItemRef.querySelector(".why-choose-us-moving-item-background-images"), {
                    yPercent: -100,
                    ease: "power1.in"
                }, "<");
                mouseEntertl.to(chooseItemRef.querySelector(".why-choose-us-moving-item-discription"), {
                    yPercent: -75,
                    justifyContent: "center",
                    ease: "power1.in"
                }, "<");
            };

            const handleMouseLeave = (chooseItemRef) => {
                const mouseEntertl2 = gsap.timeline();
                mouseEntertl2.to(chooseItemRef.querySelector(".why-choose-us-moving-item-background-images img"), {
                    scale: 1,
                    ease: "power1.out"
                });
                mouseEntertl2.to(chooseItemRef.querySelector(".why-choose-us-moving-item-background-images"), {
                    yPercent: 0,
                    ease: "power1.out"
                }, "<");
                mouseEntertl2.to(chooseItemRef.querySelector(".why-choose-us-moving-item-discription"), {
                    yPercent: 0,
                    justifyContent: "start",
                    ease: "power1.out"
                }, "<");
            };

            const chooseItemRefs = document.querySelectorAll('.why-choose-us-moving-item-main');


            chooseItemRefs.forEach((chooseItemRef) => {
                chooseItemRef.addEventListener('mouseenter', () => handleMouseEnter(chooseItemRef));
                chooseItemRef.addEventListener('mouseleave', () => handleMouseLeave(chooseItemRef));
            });

            return () => {
                chooseItemRefs.forEach((chooseItemRef) => {
                    chooseItemRef.removeEventListener('mouseenter', () => handleMouseEnter(chooseItemRef));
                    chooseItemRef.removeEventListener('mouseleave', () => handleMouseLeave(chooseItemRef));
                });
            };
        }, whyChooseRef);
        return () => ctxWhyChoose.revert();
    }, []);

    return (
        <div ref={whyChooseRef} className="why-choose-us">
            <div className="why-choose-us-main">
                <div className="why-choose-heading-main">
                    <h1 className="why-choose-heading-h1 display2">Why Choose Us</h1>
                    <hr/>
                </div>
                <div className="why-choose-us-moving">
                    <div className="why-choose-us-moving-item">
                        <div className="why-choose-us-moving-item-main">
                            <div className="why-choose-us-moving-item-background-images">
                                <img src="../assets/why/insurance.webp" alt="" />
                            </div>
                            <div className="why-choose-us-moving-item-discription">
                                <div className="why-choose-us-moving-item-discription-heading">
                                    <h1 className="display2">Future Innovative</h1>
                                    <h1 className="display5">TAX AND ACCOUNT</h1>
                                </div>
                                <div className="why-choose-us-moving-item-discription-details">
                                    <p>
                                    The in-house team of experts offer unbiased advice and guidance on your Books of Accounts, Auditing, VAT Registration, Economic Substance, Tax Filing Services, and more.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="why-choose-us-moving-item-inner"></div>
                    </div>
                    <div className="why-choose-us-moving-item">
                        <div className="why-choose-us-moving-item-main">
                            <div className="why-choose-us-moving-item-background-images">
                                <img src="../assets/why/legal.webp" alt="" />
                            </div>
                            <div className="why-choose-us-moving-item-discription">
                                <div className="why-choose-us-moving-item-discription-heading">
                                    <h1 className="display2">Future Innovative</h1>
                                    <h1 className="display5">BUSINESS HUB</h1>
                                </div>
                                <div className="why-choose-us-moving-item-discription-details">
                                    <p>
                                    Provides state-of-the-art coworking, conference, and meeting room facilities. Access our fully serviced business centre in Downtown Dubai.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="why-choose-us-moving-item-inner"></div>
                    </div>
                    <div className="why-choose-us-moving-item">
                        <div className="why-choose-us-moving-item-main">
                            <div className="why-choose-us-moving-item-background-images">
                                <img src="../assets/why/hub.webp" alt="" />
                            </div>
                            <div className="why-choose-us-moving-item-discription">
                                <div className="why-choose-us-moving-item-discription-heading">
                                    <h1 className="display2">Future Innovative</h1>
                                    <h1 className="display5">FUNDING</h1>
                                </div>
                                <div className="why-choose-us-moving-item-discription-details">
                                    <p>
                                    We offer our clients the opportunity to scale their business in terms of raising capital. Our partnership with Vestigos offers unique platforms for SMEs to raise the funds they need.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="why-choose-us-moving-item-inner"></div>
                    </div>
                    <div className="why-choose-us-moving-item">
                        <div className="why-choose-us-moving-item-main">
                            <div className="why-choose-us-moving-item-background-images">
                                <img src="../assets/why/funds.webp" alt="" />
                            </div>
                            <div className="why-choose-us-moving-item-discription">
                                <div className="why-choose-us-moving-item-discription-heading">
                                    <h1 className="display2">Future Innovative</h1>
                                    <h1 className="display5">Banking</h1>
                                </div>
                                <div className="why-choose-us-moving-item-discription-details">
                                    <p>
                                    Gain access to a comprehensive range of financial products and services tailored to meet the unique requirements of institutions worldwide. Our cutting-edge financial solutions, backed by advanced technology, offer an international banking solution that is capable of optimizing your company’s operations, reducing expenses, and enhancing efficiency. 
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="why-choose-us-moving-item-inner"></div>
                    </div>
                    <div className="why-choose-us-moving-item">
                        <div className="why-choose-us-moving-item-main">
                            <div className="why-choose-us-moving-item-background-images">
                                <img src="../assets/why/community.webp" alt="" />
                            </div>
                            <div className="why-choose-us-moving-item-discription">
                                <div className="why-choose-us-moving-item-discription-heading">
                                    <h1 className="display2">Future Innovative</h1>
                                    <h1 className="display5">COMMUNICATION HUB</h1>
                                </div>
                                <div className="why-choose-us-moving-item-discription-details">
                                    <p>
                                    Having partnered with UAE’s leading communications provider O-Square, getting access to highly qualified and trained customer service professionals is just a call away. Save time, upsell services, get customer feedback surveys, make inbound/outbound calls, and get detailed reporting on set KPIs.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="why-choose-us-moving-item-inner"></div>
                    </div>
                    <div className="why-choose-us-moving-item">
                        <div className="why-choose-us-moving-item-main">
                            <div className="why-choose-us-moving-item-background-images">
                                <img src="../assets/why/01.webp" alt="" />
                            </div>
                            <div className="why-choose-us-moving-item-discription">
                                <div className="why-choose-us-moving-item-discription-heading">
                                    <h1 className="display2">Future Innovative</h1>
                                    <h1 className="display5">EMPLOYMENT SOLUTION</h1>
                                </div>
                                <div className="why-choose-us-moving-item-discription-details">
                                    <p>
                                    Conveniently hire the best talents and receive latest updates on Payroll, Employee Appraisals, Recruitment, Labor Laws, Training and Development, Compensation and Benefits through our partner HR consultancy, People Partners.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="why-choose-us-moving-item-inner"></div>
                    </div>
                    <div className="why-choose-us-moving-item">
                        <div className="why-choose-us-moving-item-main">
                            <div className="why-choose-us-moving-item-background-images">
                                <img src="../assets/why/02.webp" alt="" />
                            </div>
                            <div className="why-choose-us-moving-item-discription">
                                <div className="why-choose-us-moving-item-discription-heading">
                                    <h1 className="display2">Future Innovative</h1>
                                    <h1 className="display5">MEDIA AND MARKETING</h1>
                                </div>
                                <div className="why-choose-us-moving-item-discription-details">
                                    <p>
                                    Creative Zone’s Media and Marketing division provides new businesses in the UAE with 360° Marketing Solutions. Our team of experts help to define the brand identity for start-ups and build a story line tangent to the business function.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="why-choose-us-moving-item-inner"></div>
                    </div>
                    <div className="why-choose-us-moving-item">
                        <div className="why-choose-us-moving-item-main">
                            <div className="why-choose-us-moving-item-background-images">
                                <img src="../assets/why/03.webp" alt="" />
                            </div>
                            <div className="why-choose-us-moving-item-discription">
                                <div className="why-choose-us-moving-item-discription-heading">
                                    <h1 className="display2">Future Innovative</h1>
                                    <h1 className="display5">CONCIERGE</h1>
                                </div>
                                <div className="why-choose-us-moving-item-discription-details">
                                    <p>
                                    The dedicated in-house concierge team offers complete solutions to corporate, family and personal requirements, including PRO, banking services, and more.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="why-choose-us-moving-item-inner"></div>
                    </div>
                    <div className="why-choose-us-moving-item">
                        <div className="why-choose-us-moving-item-main">
                            <div className="why-choose-us-moving-item-background-images">
                                <img src="../assets/why/04.webp" alt="" />
                            </div>
                            <div className="why-choose-us-moving-item-discription">
                                <div className="why-choose-us-moving-item-discription-heading">
                                    <h1 className="display2">Future Innovative</h1>
                                    <h1 className="display5">LEGAL</h1>
                                </div>
                                <div className="why-choose-us-moving-item-discription-details">
                                    <p>
                                    It is advisable to know the legal and commercial formalities right from start. The expert team at Hamdan AlShamsi Lawyers & Legal Consultants advice to SMEs, Venture Capitalists, Entrepreneurs, Investors, and Incubators.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="why-choose-us-moving-item-inner"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
