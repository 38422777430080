import '../Css/Components/MasdarCity.css';
import React, { useRef } from 'react';


const Difc = () => {

    return (
        <>
            <div className="page-hero difc">
                <div className="page-hero-top">
                    <div className="main">
                        <div className="content">
                            <div className="content-logo">
                                <img src="../assets/difc/logo.svg" alt="" />
                            </div>
                            <div className="heading">

                                <h1 className='orange'>
                                    DIFC
                                </h1>
                                <h2>
                                    Tech <span className='orange'> Start-up </span> Package
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-form">
                    <div className="main">
                        <div className="form">
                            <div className="sub" onClick={(e) => e.stopPropagation()}>
                                <form id="contactForm">
                                    <input type="text" name="name" placeholder="Full Name" />
                                    <input type="tel" name="phoneNumber" placeholder="Phone Number" />
                                    <input type="email" name="email" placeholder="Email" />
                                    <input type="text" name="subject" placeholder="Subject" />
                                    <textarea name="message" rows="4" placeholder="Message" />
                                    <input type="submit" value="SEND MESSAGE" className="btn-submit" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-bottom">
                    <div className="main">
                        <div className="content">
                            <h2>
                                About
                                <span className='orange'>&nbsp;DIFC</span>
                            </h2>
                            <p>
                                Dubai International Financial Centre (DIFC) holds a prominent position as the primary global financial center in the Middle East, Africa, and South Asia (MEASA) region. With nearly two decades of experience, DIFC has played a pivotal role in facilitating trade and investment flows throughout MEASA, a region encompassing 72 countries and boasting a combined population of around three billion people.
                            </p>
                            <p>
                                The DIFC Innovation Hub is a premier ecosystem for Tech Startups offering innovation license and DIFC fintech license in the prestigious DIFC jurisdiction. Located between Burj Khalifa and Emirates Towers, it is the perfect location for your tech startup with affordable DIFC trade license cost.
                            </p>
                            <p>
                                DIFC’s strategic location acts as a vital link, connecting the markets of the MEASA region with the economies of Asia, Europe, and the Americas, all through the vibrant city of Dubai.
                            </p>
                            <p>
                                Apply for innovation license at DIFC and join the vibrant tech community at this prime DIFC innovation hub location with Future Innovative!

                            </p>

                        </div>
                        <div className="logo">
                            <div className="main">
                                <img src="../assets/logo/logo.png" alt="" />
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            {/* Page Packages */}
            {/* Page Packages */}
            {/* Page Packages */}

            <div className="page-packages difc">
                <div className="bg">
                    <div className="main">
                        <div className="heading">
                            <h1>
                                Explore Future Innovative
                                <br />
                                <span className='orange'> All-Inclusive Package </span>in DIFC
                            </h1>
                        </div>
                        <div className="price">
                            <h2>Package Starting from <span className='orange'> AED 28,900</span></h2>
                        </div>
                        <div className="content">
                            <p>
                                This includes
                            </p>
                        </div>
                        <div className="list">
                            <div className="item">
                                <div className="main">
                                    <img src="../assets/masdar-city/01.png" alt="" />
                                </div>
                                <div className="text">
                                    <h3>
                                        Business <br />
                                        Trade License
                                    </h3>
                                </div>
                            </div>
                            <div className="item">
                                <div className="main">
                                    <img src="../assets/masdar-city/02.png" alt="" />
                                </div>
                                <div className="text">
                                    <h3>
                                        Investor/ <br />
                                        Residency Visa
                                    </h3>
                                </div>
                            </div>
                            <div className="item">
                                <div className="main">
                                    <img src="../assets/masdar-city/03.png" alt="" />
                                </div>
                                <div className="text">
                                    <h3>
                                        Up to 5 Business <br />
                                        Activities
                                    </h3>
                                </div>

                            </div>
                            <div className="item">
                                <div className="main">
                                    <img src="../assets/masdar-city/04.png" alt="" />
                                </div>
                                <div className="text">
                                    <h3>
                                        Registration <br />
                                        Fees
                                    </h3>
                                </div>
                            </div>
                            <div className="item">
                                <div className="main">
                                    <img src="../assets/masdar-city/05.png" alt="" />
                                </div>
                                <div className="text">
                                    <h3>
                                        Immigration <br />
                                        Establishment Card
                                    </h3>
                                </div>
                            </div>
                            <div className="item">
                                <div className="main">
                                    <img src="../assets/masdar-city/06.png" alt="" />
                                </div>
                                <div className="text">
                                    <h3>
                                        E-channel <br />
                                        Registration
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Packages Steps */}
            {/* Packages Steps */}
            {/* Packages Steps */}


            {/* Packages Option */}
            {/* Packages Option */}
            {/* Packages Option */}

            <div className="page-option difc">
                <div className="main">
                    <div className="heading">
                        <h1>
                            <span className='orange'> DIFC Innovation License</span>
                            <br />
                            Requirements:
                        </h1>
                    </div>
                    <div className="list">
                        <ul>
                            <li>The entity should be non-regulated and cannot conduct any financial services including financial advisory, arranging money services etc.</li>
                            <li>The entity must provide a type of technology like a Software Solution, AI, Web3, Technology R&D, or Blockchain.</li>
                            <li>The entity or business activities must be tech-related or innovation-related as this license is sector agnostic.</li>
                            <li>The entity must have a physical presence within DIFC with a minimum requirement of obtaining a flexible desk at the co-working spaces.</li>
                            <li>The entity cannot generate any trading/selling of physical products (e.g., import/export of physical products) through direct or e-commerce channels.</li>
                            <li>Source of income should be produced for compliance purposes.</li>
                        </ul>

                    </div>
                </div>

            </div>

            {/* Packages Benifits */}
            {/* Packages Benifits */}
            {/* Packages Benifits */}

            <div className="page-benifits">
                <div className="main">

                    <div className="content">
                        <div className="heading">
                            <h2>
                                Benefits of the
                                <br />
                                <span className='orange'> DIFC </span> Tech Start-up Package
                            </h2>
                        </div>
                        <div className="list">
                            <ul>
                                <li>Subsidised License by 90%</li>
                                <li>4 Visa Allocation</li>
                                <li>100% Foreign Ownership</li>
                                <li>Governed by the UK Common Law and not the UAE Sharia Civil Law</li>
                                <li>Access to a Thriving Start-up Ecosystem</li>
                                <li>ZERO Currency Restrictions</li>
                                <li>No Restriction on Capital Repatriation</li>
                                <li>Access to DIFC Co-working Facilities and Modern Amenities</li>
                                <li>Access to a Global Fintech Hub</li>
                            </ul>
                        </div>
                    </div>

                    <div className="page-benifits-image">
                        <img src="../assets/difc/benifits.png" alt="" />
                    </div>
                </div>

            </div>




        </>
    )
}

export default Difc;