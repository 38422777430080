import "../Css/Footer.css";
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faMobileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';

export default function Footer() {
    return (
        <>
            <div className="footer-border-top">

            </div>
            <div className="footer">
                <div className="footer-main">
                    <div className="footer-main-logo">
                        <img src="../assets/logo/logo.png" alt="Future Innovative Logo" />
                        <h1>FUTURE INNOVATIVE</h1>
                        <h2>CORPORATE SERVICES LLC</h2>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        </p>
                    </div>
                    <div className="footer-our-servies">
                        <h3>
                            Our Servies
                        </h3>
                        <ul>
                            <li>
                            <a href="../banking">Banking</a>
                            </li>
                            <li>
                            <a href="../business-hub">Business Hub</a>
                            </li>
                            <li>
                            <a href="../communications-hub">Communication Hub</a>
                            </li>
                            <li>
                            <a href="../funding">Funding</a>
                            </li>
                            <li>
                            <a href="../hr-solutions">HR Solution</a>
                            </li>
                            <li>
                            <a href="../media-marketing">Media Marketing</a>
                            </li>
                            <li>
                            <a href="../tax-accounts">Text & Account</a>
                            </li>
                        </ul>
                       
                    </div>
                    <div className="footer-follow-us">
                        <h3>
                            Follow Us
                        </h3>
                        <div className="footer-social-icons">
                            <div className="footer-social-icon">
                                <FontAwesomeIcon icon={faFacebookF} className="icon" />
                            </div>
                            <div className="footer-social-icon">
                                <FontAwesomeIcon icon={faInstagram} className="icon" />
                            </div>
                            <div className="footer-social-icon">
                                <FontAwesomeIcon icon={faTwitter} className="icon" />
                            </div>
                            {/* <div className="footer-social-icon">
                                <FontAwesomeIcon icon={faFacebookF} className="icon" />
                            </div>
                            <div className="footer-social-icon">
                                <FontAwesomeIcon icon={faFacebookF} className="icon" />
                            </div> */}
                        </div>

                        <h3>
                            Contact Us
                        </h3>

                        <div className="contact-us-content">
                            <div className="contact-us-items">
                                <p>
                                   
                                    +971&nbsp;4&nbsp;311&nbsp;6539
                                  
                                    
                                </p>
                                <div className="contact-us-items-icon">
                                    <FontAwesomeIcon icon={faPhone} className="icon " />
                                </div>
                            </div>
                            <div className="contact-us-items">
                                <p>
                                    +971&nbsp;54&nbsp;279&nbsp;9339
                                </p>
                                <div className="contact-us-items-icon">
                                    <FontAwesomeIcon icon={faMobileAlt} className="icon mobile" />
                                </div>
                            </div>
                            <div className="contact-us-items">
                                <p>
                                    
                                    info@futureinnovativecs.ae
                                 
                                   
                                </p>
                                <div className="contact-us-items-icon">
                                    <FontAwesomeIcon icon={faEnvelope} className="icon" />
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
                <div className="footer-bottom-border">

                </div>

            </div>
        </>
    )
}
