import React, { useState } from 'react'
import '../Css/Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

export default function Header() {
    const [click, setClick] = useState(false);
    const [dropdown, setDropdown] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const onMouseEnter = () => {
        if (window.innerWidth < 960) {
            setDropdown(true);
        } else {
            setDropdown(true);
        }
    };

    const onMouseLeave = () => {
        if (window.innerWidth < 960) {
            setDropdown(false);
        } else {
            setDropdown(false);
        }
    };
    return (
        <div className="header">
            <div className="main">
                <a href="/">
                    <div className="logo">
                    <img src="../assets/logo/logo.png" alt="" />
                    </div>
                </a>
                <div className='menu-icon' onClick={handleClick}>
                    <FontAwesomeIcon className='icon'  icon={click ? faTimes : faBars} />
                </div>
                <nav className={click ? 'active' : ''}>
                    <ul>
                        <li>
                            <a href="../about-us">
                                About Us
                            </a>
                        </li>
                        <li>
                            <a href="#">
                               Jurisdiction
                            </a>
                            <FontAwesomeIcon icon={faChevronDown} className='down-arrow' />

                            <ul className='header-sub-menu'>
                                <li>
                                    <a href="../masdar-city">Masdar City Free Zone</a>
                                </li>
                                <li>
                                    <a href="../difc">Dubai International Financial Center (DIFC)</a>
                                </li>
                                <li>
                                    <a href="../dmmc">Dubai Multi Commodities Center (DMCC)</a>
                                </li>
                                <li>
                                    <a href="../dwtc">Dubai World Trade Center (DWTC)</a>
                                </li>
                                <li>
                                    <a href="../dubai-design-district-d3">Dubai Design District (D3)</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="../aew-servie">
                                Promotion
                            </a>
                        </li>
                        <li>
                            <a href="#">
                              Services
                            </a>
                            <FontAwesomeIcon icon={faChevronDown} className='down-arrow' />

                            <ul className='header-sub-menu'>
                                <li>
                                    <a href="../banking">Banking</a>
                                </li>
                                <li>
                                    <a href="../business-hub">Business Hub</a>
                                </li>
                                <li>
                                    <a href="../communications-hub">Communication Hub</a>
                                </li>
                                <li>
                                    <a href="../funding">Funding</a>
                                </li>
                                <li>
                                    <a href="../hr-solutions">HR Solution</a>
                                </li>
                                <li>
                                    <a href="../media-marketing">Media Marketing</a>
                                </li>
                                <li>
                                    <a href="../tax-accounts">Text & Account</a>
                                </li>
                            </ul>
                        </li>
                        {/* <li>
                            <a href="">
                                Projects
                            </a>
                        </li> */}
                        <li>
                            <a href="../career">
                                Events
                            </a>
                        </li>
                        <li>
                            <a href="">
                              Resources
                            </a>
                            <FontAwesomeIcon icon={faChevronDown} className='down-arrow' />

                            <ul className='header-sub-menu'>
                                <li>
                                    <a href="#">Blog</a>
                                </li>
                                <li>
                                    <a href="#">Press Releases</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="../Footer">
                                Contact Us
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}
