import '../Css/Components/MasdarCity.css';
import React from 'react'
import '../Css/Components/Need.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';


const Funding = () => {


    return (
        <>
            <div className="page-hero fund">
                <div className="page-hero-top">
                    <div className="main">
                        <div className="content">
                            <div className="content-logo">
                            </div>
                            <div className="heading">

                                <h1>
                                    Raise Your Business Into New  Heights with
                                    <br />
                                    <span className='orange'>FUTURE INNOVATIVE</span>
                                </h1>
                                <br />
                                <h3>
                                    Fueling your dreams with financial support:
                                    <br />
                                    Our funding service has got you covered
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-form">
                    <div className="main">
                        <div className="form">
                            <div className="sub" onClick={(e) => e.stopPropagation()}>
                                <form id="contactForm">
                                    <input type="text" name="name" placeholder="Full Name" />
                                    <input type="tel" name="phoneNumber" placeholder="Phone Number" />
                                    <input type="email" name="email" placeholder="Email" />
                                    <input type="text" name="subject" placeholder="Subject" />
                                    <textarea name="message" rows="4" placeholder="Message" />
                                    <input type="submit" value="SEND MESSAGE" className="btn-submit" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-bottom">
                    <div className="main">
                        <div className="content">
                            <h2>
                                FUTURE INNOVATIVE
                                <br />
                                <span className='orange'>FUNDING</span>
                            </h2>
                            <p>
                                FUTURE INNOVATIVE’s funding partner, VESTIGOS, offers a broad range of services to meet your needs – from validating your business idea to assisting you in creating the finest business plan to increase your chances of attracting investors’ interest.
                            </p>
                        </div>
                        <div className="logo">
                            <div className="main">
                                <img src="../assets/logo/logo.png" alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className="need-ccomponenets">
                <div className="headings" >
                    <h1 >Our<strong className='orange'>&nbsp;Servies</strong></h1>
                </div>
                <div className="need-ccomponenets-main">
                    <div className="need-ccomponenets-card fund1">
                        <div className="deatils">
                            <div className="main">
                                <h1>
                                    Investment Readiness
                                </h1>
                                <hr />
                                <p >
                                    Vestigos helps you build the “right Deck for the right investor”. The investment readiness preparation approach aligns your need to raise money with what an investor seeks as value. Whether you need to focus on overall strategy, technology, market rollout or financing, they will build a customized plan to address each area effectively, following best-in-class practices.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="need-ccomponenets-card fund2">
                        <div className="deatils">
                            <div className="main">
                                <h1>
                                    NetWorking
                                </h1>
                                <hr />
                                <p  >
                                    Networking with the right people, partners, and suppliers is a key success factor for any new or existing business. The extended local and international network will offer you the opportunity to expand your network and secure strategic partnerships or alliances
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="need-ccomponenets-card fund3">
                        <div className="deatils">
                            <div className="main">
                                <h1>
                                    Financing Advisory
                                </h1>
                                <hr />
                                <p  >
                                    Securing the necessary financial resources is paramount for companies seeking funding and investors. Whether you require seed funding to complete your MVP, capital from the private equity market to enhance your growth or are pursuing an M&A transaction, Vestigos offers tailored strategies and plans to meet your financial needs. Their end-to-end service is designed to support you throughout all stages of the funding and M&A processes – market identification, evaluation, initiation, discussions, pitching, negotiations, and closing.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            {/* Why Coose Us */}

            <div className="page-hero">
                {/* Page Advantage */}
                {/* Page Advantage */}
                {/* Page Advantage */}
                <div className="page-hero-adavntage">
                    <div className="main">
                        <div className="content">
                            <div className="heading">
                                <h1>
                                    What
                                    <span className='orange'>&nbsp;Do You Get?</span>
                                </h1>
                            </div>
                            <div className="list">


                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>

                                        <div className="dis">
                                            <p>
                                                Vestigos specializes into investment readiness for startups and SMEs
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>

                                        <div className="dis">
                                            <p>
                                                Connects ideas and highly disruptive solutions to the world
                                            </p>
                                        </div>
                                    </div>

                                </div>

                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>

                                        <div className="dis">
                                            <p>
                                                Validates and creates robust financial models and valuations
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>

                    </div>
                </div>

            </div>

            {/* Packages Steps */}
            {/* Packages Steps */}
            {/* Packages Steps */}

            <div className="packages-steps">
                <div className="main">
                    <div className="sticky">
                        <div className="heading">
                            <h2>
                                How it Works
                            </h2>
                            <h1 className='orange'>
                                From <span className='orange'>&nbsp;FUTURE INNOVATIVE</span>
                            </h1>

                            <div className="dis">
                                <p>
                                    Setting up a company can sometimes seem challenging, but Future Innovative is here to simplify your company formation and save you time and money.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="move">
                        <div className="list">
                            <div className="item">
                                <div className="number">
                                    <h1>1</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Complete the Eligibility Form
                                    </h2>
                                </div>
                            </div>
                            <div className="item">
                                <div className="number">
                                    <h1>2</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Get a Quick Approval Response
                                    </h2>
                                </div>
                            </div>
                            <div className="item">
                                <div className="number">
                                    <h1>3</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Pass the Due Diligence
                                    </h2>
                                </div>
                            </div>
                            <div className="item">
                                <div className="number">
                                    <h1>4</h1>
                                </div>
                                <div className="content">
                                    <h2>
                                        Discuss Funding Options
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </>
    )
}

export default Funding;
