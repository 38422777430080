import "../Css/Choose.css"
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef } from 'react';

gsap.registerPlugin(ScrollTrigger);

export default function Choose() {
    const chooseRef = useRef();

    useLayoutEffect(() => {
        let ctxChoose = gsap.context(() => {
            const choosetl1 = gsap.timeline();
            choosetl1.from(".choose-heading-h1", {
                y: 350,
                duration: 0.15
            });

            choosetl1.from(".choose-hr", {
                width: 0,
                duration: 0.15

            });

            const choosetl2 = gsap.timeline();
            choosetl2.from(".choose-moving-part-main-part-inner", {
                xPercent: 102,
                duration: 0.15,
                delay: 0.1,
            });
            choosetl2.from(".choose-moving-part-main-part-image", {
                xPercent: 102,
                duration: 0.25,
                stagger: 0.15,
                delay: 0.1,
            }, "<");
            choosetl2.to(".choose-moving-part-main-part-inner", {
                xPercent: -102,
                duration: 0.25,
            });
            choosetl2.from(".choose-moving-part-main-part-details", {
                yPercent: 26,
                duration: 0.15,
                stagger: 0.15
            });




            ScrollTrigger.create({
                trigger: ".choose-heading-main",
                start: "start 80%",
                animation: choosetl1,
                toggleActions: "restart none none reverse",
               // markers: false
            });

            ScrollTrigger.create({
                trigger: ".choose-moving-part",
                start: "start 80%",
                animation: choosetl2,
                toggleActions: "restart none none reverse",
                //markers: false
            });

        }, chooseRef);

        return () => ctxChoose.revert();
    });

    return (
        <>
            <div ref={chooseRef} className="choose">
                <div className="choose-main">
                    {/* Main Heading */}
                    {/* Main Heading */}
                    {/* Main Heading */}
                    <div className="choose-heading-main">
                        <h1 className="choose-heading-h1 display2">Choose Your Business</h1>
                        <hr className="choose-hr" />
                    </div>
                    {/* Main Moving Part */}
                    {/* Main Moving Part */}
                    {/* Main Moving Part */}
                    <div className="choose-moving-part">
                        <div className="choose-moving-part-main">
                            <div className="choose-moving-part-main-part">
                                <div className="choose-moving-part-main-part-inner">
                                </div>
                                <div className="choose-moving-part-main-part-image">
                                    <img src="../assets/choose/01.webp" alt="" />
                                    <div className="choose-moving-part-main-part-details">
                                        <div className="choose-moving-part-main-part-details-headings">
                                            <h1 className="display2">Mainland</h1>
                                            <h1 className="display5">Comapny Setup</h1>
                                        </div>
                                        <div className="choose-moving-part-main-part-details-discription">
                                            <p >
                                                Set up your company in Mainland and enjoy your freedom to conduct business anywhere in the UAE.
                                            </p>
                                            <br />
                                            <p>
                                                Get access to a wide range of investment opportunities, and benefit from the Emirate’s favorable business environment. Mainland company setup enjoys a broad scope of trade and business activities within and outside the UAE.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="choose-moving-part-main-part">
                                <div className="choose-moving-part-main-part-inner logo">
                                    <img src="../assets/logo/logo.png" alt="" />
                                </div>
                                <div className="choose-moving-part-main-part-image">
                                    <img src="../assets/choose/02.webp" alt="" />
                                    <div className="choose-moving-part-main-part-details">
                                        <div className="choose-moving-part-main-part-details-headings">
                                            <h1 className="display2">Free&nbsp;zone</h1>
                                            <h2>Comapny Setup</h2>
                                        </div>
                                        <div className="choose-moving-part-main-part-details-discription">
                                            <p >
                                                Discover the perfect jurisdiction for your business activity from a diverse range of over 50 free zones in the UAE.
                                            </p>
                                            <br />
                                            <p className='special' >
                                                For multinational companies, free zone company setups offer significant advantages. Enjoy full foreign company ownership, benefit from the attractive low tax rates, and tap into the dynamic business ecosystem of the UAE.
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="choose-moving-part-main-part">
                                <div className="choose-moving-part-main-part-inner">

                                </div>
                                <div className="choose-moving-part-main-part-image">
                                    <img src="../assets/choose/03.webp" alt="" />
                                    <div className="choose-moving-part-main-part-details">
                                        <div className="choose-moving-part-main-part-details-headings">
                                            <h1 className="display2">Offshore</h1>
                                            <h2>Comapny Setup</h2>
                                        </div>
                                        <div className="choose-moving-part-main-part-details-discription">
                                            <p  >
                                                Conduct business activities without the need for any physical presence in the UAE. Just like in Mainland and Free Zone, enjoy a favorable business ecosystem in a more convenient setup.
                                            </p>
                                            <br />
                                            <p className='special' >
                                                Offshore companies also get access to the UAE’s wide range of services, such as trade finance, business consultancy, and legal and financial advice.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
