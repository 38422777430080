import React from 'react';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
// import Header from '../Components/Header.js'
import Home from '../Pages/Home.js'
import MasdarCity from '../Pages/MasdarCity.js'
import Difc from '../Pages/Difc.js'
import Dmmc from '../Pages/Dmmc.js'
import Dwtc from '../Pages/Dwtc.js'
import D3 from '../Pages/D3.js'
import TaxAccount from '../Pages/TaxAccount.js'
import BusinessHub from '../Pages/BusinessHub.js'
import Banking from '../Pages/Banking.js'
import HrSolution from '../Pages/HrSolution.js'
import Funding from '../Pages/Funding.js'
import Communication from '../Pages/Communication.js'
import MediaMarketing from '../Pages/MediaMarketing.js'
import Header from '../componenets/Header.js';
import Footer from '../componenets/Footer.js';

export default function RoutesFunction() {
    return (
        <Router>
          <Header/>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/masdar-city" element={<MasdarCity />} />
                <Route path="/difc" element={<Difc />} />
                <Route path="/dmmc" element={<Dmmc />} />
                <Route path="/dwtc" element={<Dwtc />} />
                <Route path="/dubai-design-district-d3" element={<D3 />} />


                <Route path="/tax-accounts" element={<TaxAccount />} />
                <Route path="/business-hub" element={<BusinessHub />} />
                <Route path="/banking" element={<Banking />} />
                <Route path="/hr-solutions" element={<HrSolution />} />
                <Route path="/funding" element={<Funding />} />
                <Route path="/communications-hub" element={<Communication />} />
                <Route path="/media-marketing" element={<MediaMarketing />} />
            </Routes>
            <Footer/>
        </Router>

    )
}
