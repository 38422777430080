import '../Css/Components/MasdarCity.css';
import React, { useEffect, useRef } from 'react'
import ClientSlider from './ClientSlider.js';
import '../Css/Components/Need.css';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

let clients = [
    {
        name: "Business Package",
        disc: "The Business Package is designed to maximize flexibility for companies with specific requirements and is available to new and existing businesses.",
    },
    {
        name: "Women Entrepreneurship Package",
        disc: "The Women Entrepreneurship Package is exclusively for women entrepreneurs and is designed to drive the growth of women-led companies in the United Arab Emirates.",
    },
    {
        name: "Start-Up Package",
        disc: "The Start-Up Package is designed to support start-up ventures and foster innovation.",
    }
]
var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    responsive: [
        {
            breakpoint: 990,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 530,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}


const BusinessHub = () => {
    const headingRef1 = useRef(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const heading = headingRef1.current;

        const th = gsap.timeline({
            scrollTrigger: {
                trigger: heading,
                start: "top 85%",
                end: "bottom 0%",
                toggleActions: "restart none none none",

            }
        });

        th.from(heading, {
            opacity: 0,
            y: "+=50",
            duration: 0.3
        }).to(heading, {
            opacity: 1,
            y: 0,
        });
        return () => {
            th.kill(); // Kill the timeline to revert the animation
        };
    }, []);


    const arrowRef = useRef(null);
    let clientDisc = "";
    clientDisc = clients.map((item, i) => (
        <ClientSlider item={item} key={i} />
    ));


    return (
        <>
            <div className="page-hero bank">
                <div className="page-hero-top">
                    <div className="main">
                        <div className="content">
                            <div className="content-logo">
                            </div>
                            <div className="heading">

                                <h1>
                                    Global Banking Solutions for
                                    <span>&nbsp;Your Corporate Needs</span>
                                </h1>
                                <br />
                                <h3>
                                    World-class financial services that <br />
                                    meet the needs of any business
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-form">
                    <div className="main">
                        <div className="form">
                            <div className="sub" onClick={(e) => e.stopPropagation()}>
                                <form id="contactForm">
                                    <input type="text" name="name" placeholder="Full Name" />
                                    <input type="tel" name="phoneNumber" placeholder="Phone Number" />
                                    <input type="email" name="email" placeholder="Email" />
                                    <input type="text" name="subject" placeholder="Subject" />
                                    <textarea name="message" rows="4" placeholder="Message" />
                                    <input type="submit" value="SEND MESSAGE" className="btn-submit" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-bottom">
                    <div className="main">
                        <div className="content">
                            <h2>
                                FUTURE INNOVATIVE
                                <br />
                                <span className='orange'>BANKING</span>
                            </h2>
                            <p>
                                Gain access to a comprehensive range of financial products and services tailored to meet the unique requirements of companies worldwide. Our cutting-edge financial solutions, backed by advanced technology, offer an international banking solution that is capable of optimizing your company’s operations, reducing expenses, and enhancing efficiency.
                            </p>
                            <p>
                                Our financial products and services with technology solutions provide an international solution for the global banking industry, ready to streamline your company’s operations, reducing costs and improving efficiency.    Should you wish your office be located in one of Dubai’s most prestigious Downtown Dubai addresses, we recommend our co-working and meeting facility at Emaar Square Building 4, Downtown, Dubai.
                            </p>
                        </div>
                        <div className="logo">
                            <div className="main">
                                <img src="../assets/logo/logo.png" alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>





            {/* Page Services */}
            {/* Page Services */}
            {/* Page Services */}

            <div className="page-servies bank">
                <div className="bg">
                    <div className="main">
                        <div className="heading">
                            <h1>
                                Our
                                <span className='orange'>&nbsp;Services</span>
                            </h1>
                        </div>
                        <div className="content">
                            <h3>
                                Masdar City offers comprehensive business support services, including fast licensing, residence visas for staff, and affordable leasing options for shared workspaces, executive suites, and larger corporate needs.
                            </h3>
                        </div>
                    </div>
                </div>

            </div>



            {/* Why Coose Us */}

            <div className="page-hero">
                {/* Page Advantage */}
                {/* Page Advantage */}
                {/* Page Advantage */}
                <div className="page-hero-adavntage">
                    <div className="main">
                        <div className="content">
                            <div className="heading">
                                <h1>
                                    What
                                    <span className='orange'>&nbsp;Do You Get?</span>
                                </h1>
                            </div>
                            <div className="list">
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>

                                        <div className="dis">
                                            <p>
                                                Work and meet clients in prestigious Dubai Downtown.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>

                                        <div className="dis">
                                            <p>
                                                Access to a larger pool of funding options, such as international lenders, private investors, and venture capital firms.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>

                                        <div className="dis">
                                            <p>
                                                Enhanced company efficiency through streamlining your banking process..
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>

                                        <div className="dis">
                                            <p>
                                                Security against fraud and cyber threats
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>

                                        <div className="dis">
                                            <p>
                                                Commitment to delivering outstanding service and transparent pricing structure.
                                            </p>
                                        </div>
                                    </div>

                                </div>

                                <div className="item">
                                    <div className="inner">
                                        <div className="logo">
                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                        </div>

                                        <div className="dis">
                                            <p>
                                                Compliance support, enabling you to successfully navigate regulatory challenges and ensure adherence to industry standards.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>

                    </div>
                </div>

            </div>

            {/* Packages Benifits */}
            {/* Packages Benifits */}
            {/* Packages Benifits */}

            <div className="page-benifits">
                <div className="main">
                    <div className="content">
                        <div className="heading">
                            <h2>
                                Why Choose
                                <span className='orange'>&nbsp;FUTURE INNOVATIVE&nbsp;</span>
                                <br />
                                Banking Solution?
                            </h2>
                        </div>
                        <div className="list">
                            <ul>

                                <li>Value for money</li>
                                <li>Transparent transactions</li>
                                <li>Customer-centric service</li>
                                <li>Ease of setup</li>
                                <li>Trusted by over 2,000 businesses in the UAE</li>
                                <li>Experienced team of business experts</li>
                                <li>Strong community</li>

                            </ul>
                        </div>
                    </div>

                    <div className="page-benifits-image">
                        <img src="../assets/png/03.png" alt="" />
                    </div>
                </div>

            </div>



        </>
    )
}

export default BusinessHub;
