import '../Css/Components/MasdarCity.css';
import React from 'react'
import '../Css/Components/Need.css';


const MediaMarketing = () => {


    return (
        <>
            <div className="page-hero media">
                <div className="page-hero-top">
                    <div className="main">
                        <div className="content">
                            <div className="content-logo">
                            </div>
                            <div className="heading">

                                <h1>
                                    Your Extended Marketing Team
                                    <br />
                                    <span className='orange'>FUTURE INNOVATIVE</span>
                                </h1>
                                <br />
                                <h3>
                                    360° solutions to build your brand
                                    <br />and make it stand out
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-form">
                    <div className="main">
                        <div className="form">
                            <div className="sub" onClick={(e) => e.stopPropagation()}>
                                <form id="contactForm">
                                    <input type="text" name="name" placeholder="Full Name" />
                                    <input type="tel" name="phoneNumber" placeholder="Phone Number" />
                                    <input type="email" name="email" placeholder="Email" />
                                    <input type="text" name="subject" placeholder="Subject" />
                                    <textarea name="message" rows="4" placeholder="Message" />
                                    <input type="submit" value="SEND MESSAGE" className="btn-submit" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-bottom">
                    <div className="main">
                        <div className="content">
                            <h2>
                                FUTURE INNOVATIVE
                                <br />
                                <span className='orange'>MEDIA & MARKETING</span>
                            </h2>
                            <p>
                                Our team of experts helps define a brand identity for start-ups and builds a storyline aligned with its business function. From creating logo designs to designing full-fledged websites, we provide all the services necessary to help start-ups launch their brand from Day 1. A significant part of our solution is offering entrepreneurs marketing support at every step of their journey, from strategizing and planning to execution.
                            </p>
                        </div>
                        <div className="logo">
                            <div className="main">
                                <img src="../assets/logo/logo.png" alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className="need-ccomponenets">
                <div className="headings" >
                    <h1 >Our<strong className='orange'>&nbsp;Servies</strong></h1>
                </div>
                <div className="need-ccomponenets-main">
                    <div className="need-ccomponenets-card media1">
                        <div className="deatils">
                            <div className="main">
                                <h1>
                                    Website
                                    <br />Services
                                </h1>
                                <hr />
                                <p  >
                                    Website Design and Development
                                    <br />
                                    UI Design
                                    <br />
                                    UX Design
                                </p>

                            </div>
                        </div>
                    </div>
                    <div className="need-ccomponenets-card media2">
                        <div className="deatils">
                            <div className="main">
                                <h1>
                                    Communication
                                    <br />
                                    and PR
                                </h1>
                                <hr />
                                <p  >
                                    Communication Plan
                                    <br />
                                    Content Strategy
                                    <br />
                                    Blog Articles
                                    <br />
                                    Press Releases
                                    <br />
                                    Media Distribution
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="need-ccomponenets-card media3">
                        <div className="deatils">
                            <div className="main">
                                <h1>
                                    Media
                                    <br /> Production
                                </h1>
                                <hr />
                                <p  >
                                    Video Shoot
                                    <br />
                                    Video Edits
                                    <br />
                                    Photoshoot
                                </p>

                            </div>
                        </div>
                    </div>
                </div>

            </div>


            {/* Packages Benifits */}
            {/* Packages Benifits */}
            {/* Packages Benifits */}

            <div className="page-benifits">
                <div className="main">
                    <div className="content">
                        <div className="heading">
                            <h2>
                                About
                                <span className='orange'>&nbsp; FUTURE INNOVATIVE &nbsp;</span>
                                <br />
                                Media & Marketing Team
                            </h2>
                        </div>
                        <div className="list">
                            <p>
                                We believe that actions speak louder than words. Our management team will show you how we’re your company’s ideal marketing support system. We have over a decade of regional experience helping new and existing brands establish themselves.
                                <br />
                                <br />
                                We love to meet ambitious entrepreneurs with truly inspiring ideas and will do everything we can to help you turn your vision into a tangible, lasting brand experience.
                            </p>
                        </div>
                    </div>

                    <div className="page-benifits-image">
                        <img src="../assets/png/06.png" alt="" />
                    </div>
                </div>

            </div>


            {/* Page Services */}
            {/* Page Services */}
            {/* Page Services */}

            <div className="page-servies media">
                <div className="bg">
                    <div className="main">
                        <div className="heading">
                            <h1>
                                Let Us Help Define
                                <span className='orange'>&nbsp;Your Brand</span>
                                <br />
                                Identity!
                            </h1>
                        </div>
                        <div className="content">
                            <h3>
                                At FUTURE INNOVATIVE, we assist with setting up over 200 new businesses each month and offer them a portfolio of services to get their businesses going. We understand the fine line between balancing invaluable brand awareness and crucial bottom-line return on investments. Our team of accomplished creative and digital marketers and strategists have mastered the art of enabling growing companies to stay ahead of the curve by giving them all the tools required to build a brand in the digital and offline landscape efficiently.
                                <br />
                                <br />

                                Our specialised team focuses on creating a favourable image by defining brand guidelines, tone of voice, look and feel, and brand persona. We offer various services, including Digital, Social, PR, Content, and Media Production, at highly affordable rates that best fit a start-up.
                            </h3>
                        </div>
                    </div>
                </div>

            </div>





        </>
    )
}

export default MediaMarketing;