import "../Css/Steps.css"
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef } from 'react';



export default function Steps() {
    const stepsRef = useRef();


    useLayoutEffect(() => {
        let ctxChoose = gsap.context(() => {
            const choosetl1 = gsap.timeline();
            choosetl1.from(".start-business-part-main-heading-h1 ", {
                y: 350,
                duration: 0.75
            });
            choosetl1.from(".start-business-part-main-heading-h2 ", {
                y: 350,
                duration: 1
            }, "<");

            choosetl1.from(".start-business-part-main-heading-hr", {
                width: 0,
                duration: 1

            });

            ScrollTrigger.create({
                trigger: ".start-business-part-main-heading",
                start: "start 80%",
                animation: choosetl1,
                toggleActions: "restart none none reverse",
                // markers: true
            });



        }, stepsRef);

        return () => ctxChoose.revert();
    });


    // Steps Animation
    // Steps Animation
    // Steps Animation
    
    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        let ctx = gsap.context(() => {
            const hedert1 = gsap.timeline();

            hedert1.from(".start-business-card", {
                y: 100,
                delay: 0.1,
                duration: 0.25,
                opacity: 0,
                stagger: 0.2
            });

            ScrollTrigger.create({
                trigger: ".start-business",
                start: "top 80%",
                end: "bottom 5%",
                toggleActions: "restart none none reverse",
                animation: hedert1,
                markers: false
            });

        }, stepsRef);

        return () => ctx.revert();
    });

    return (

        <div ref={stepsRef} className="step-part">
            <div className="start-business-part">
                <div className="start-business-part-main">
                    {/* Heading */}
                    {/* Heading */}
                    {/* Heading */}

                    <div className="start-business-part-main-heading">
                        <h1 className="start-business-part-main-heading-h1 display2">Easy Steps</h1>
                        <h1 className="start-business-part-main-heading-h2 display3">To Start Your Business in the UAE</h1>
                        <hr className="start-business-part-main-heading-hr" />
                    </div>
                    <div className="start-business">
                        <div className="start-business-card" id='step-1'>
                            <div className="start-business-card-des bg1-l">
                                <div className="start-business-card-step-number">
                                    <h2>STEP</h2>
                                    <h1>1</h1>
                                </div>
                                <div className="start-business-card-step-teading">
                                    <h1 className="display4">
                                        Free Consultation with Business
                                        <br />
                                        <span className="orange">
                                            Setup Advisor
                                        </span>
                                    </h1>
                                </div>
                                <div className="start-business-card-step-contant">
                                    <p className="lead">
                                        Arrange a face-to-face meeting or a Zoom call with one of our advisors. We recommend having a basic understanding of your business activity, the number of shareholders, your target customers, and office space requirements. Even if you need assistance, our team is ready to support you in finalizing your business plan.
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="start-business-card" id='step-2'>
                            <div className="start-business-card-des bg2-l">
                                <div className="start-business-card-step-number">
                                    <h2>STEP</h2>
                                    <h1>2</h1>
                                </div>
                                <div className="start-business-card-step-teading">
                                    <h1 className="display4">
                                        Selecting the Right
                                        <br />
                                        <span className="orange">
                                            Jurisdiction
                                        </span>
                                    </h1>
                                </div>

                                <div className="start-business-card-step-contant">
                                    <p className="lead">
                                        We will assist you in selecting the right jurisdiction for your company, tailored to your unique needs and target market. You can establish your business in the UAE Mainland for maximum operational flexibility, leverage the benefits of a Free Zone setup if you specialize in a specific industry or seek cost-effective solutions, or consider an offshore setup to take advantage of the UAE’s thriving business environment from anywhere in the world.
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="start-business-card" id='step-3'>
                            <div className="start-business-card-des bg3-l">
                                <div className="start-business-card-step-number">
                                    <h2>STEP</h2>
                                    <h1>3</h1>
                                </div>
                                <div className="start-business-card-step-teading">
                                    <h1 className="display4">
                                        Applying for
                                        <br />
                                        <span className="orange">
                                            Trade License
                                        </span>
                                    </h1>
                                </div>

                                <div className="start-business-card-step-contant">
                                    <p className="lead">
                                        Prepare all your registration documents, including your Application Form, Memorandum of Association, Certificate of Incorporation, Bank and Residency Statements, and more. Requirements differ depending on the license type and jurisdiction, so our team will gladly assist you throughout the process.
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="start-business-card" id='step-4'>
                            <div className="start-business-card-des bg4-l">
                                <div className="start-business-card-step-number">
                                    <h2>STEP</h2>
                                    <h1>4</h1>
                                </div>
                                <div className="start-business-card-step-teading">
                                    <h1 className="display4">
                                        Initiating Visa and
                                        <br />
                                        <span className="orange">
                                            Emirates ID Applications
                                        </span>
                                    </h1>
                                </div>
                                <div className="start-business-card-step-contant">
                                    <p className="lead">
                                        Before relocating to the UAE, it is essential to apply for the necessary visas for yourself, your employees, and any dependents, if required. The specific visa options and the availability of visas for your staff and dependents will depend on the jurisdiction you have selected for your business. Our team will guide you through the visa application process and provide relevant information and support to ensure a smooth and successful transition to the UAE.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="start-business-card" id='step-5'>
                            <div className="start-business-card-des bg5-l">
                                <div className="start-business-card-step-number">
                                    <h2>STEP</h2>
                                    <h1>5</h1>
                                </div>
                                <div className="start-business-card-step-teading">
                                    <h1 className="display4">
                                        Opening a Corporate
                                        <br />
                                        <span className="orange">
                                            Bank Account
                                        </span>
                                    </h1>
                                </div>
                                <div className="start-business-card-step-contant">
                                    <p className="lead">
                                        A corporate bank account is necessary to conduct business in the UAE. You can choose from several national, international, and digital banks, depending on your financial requirements. Our team of experts will help you determine the best bank for your needs and prepare the requirements for submission to the bank.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

