import './App.css';
import RoutesFunction from './Routes/RoutesFunction.js'

function App() {
  return (
    <div className="App">
      <RoutesFunction/>
    </div>
  );
}

export default App;
