import '../Css/Components/MasdarCity.css';
import React from 'react'
import '../Css/Components/Need.css';


const HrSolution = () => {


    return (
        <>
            <div className="page-hero hr">
                <div className="page-hero-top">
                    <div className="main">
                        <div className="content">
                            <div className="content-logo">
                            </div>
                            <div className="heading">

                                <h1>
                                    Leader in Employment Solutions
                                    <br />
                                    <span className='orange'>with FUTURE INNOVATIVE</span>
                                </h1>
                                <br />
                                <h3>
                                    Discover and retain top talent <br />
                                    across diverse industries
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-form">
                    <div className="main">
                        <div className="form">
                            <div className="sub" onClick={(e) => e.stopPropagation()}>
                                <form id="contactForm">
                                    <input type="text" name="name" placeholder="Full Name" />
                                    <input type="tel" name="phoneNumber" placeholder="Phone Number" />
                                    <input type="email" name="email" placeholder="Email" />
                                    <input type="text" name="subject" placeholder="Subject" />
                                    <textarea name="message" rows="4" placeholder="Message" />
                                    <input type="submit" value="SEND MESSAGE" className="btn-submit" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-hero-bottom">
                    <div className="main">
                        <div className="content">
                            <h2>
                                FUTURE INNOVATIVE
                                <br />
                                <span className='orange'>EMPLOYMENT SOLUTIONS</span>
                            </h2>
                            <p>
                                At FUTURE INNOVATIVE, we go beyond business setup services and offer comprehensive employment solutions tailored to your workforce needs. With our expertise in HR and recruitment, we provide end-to-end solutions that include talent acquisition, onboarding, payroll management, and employee benefits administration. Whether you need temporary staff, permanent employees, or specialized professionals, our dedicated team ensures a seamless and efficient process, allowing you to focus on your core business.
                            </p>
                        </div>
                        <div className="logo">
                            <div className="main">
                                <img src="../assets/logo/logo.png" alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className="need-ccomponenets">
                <div className="headings" >
                    <h1 >Our<strong className='orange'>&nbsp;Servies</strong></h1>
                </div>
                <div className="need-ccomponenets-main ">
                    <div className="need-ccomponenets-card hr1">
                        <div className="deatils">
                            <div className="main">
                                <h1>
                                    Employee
                                    <br /> Outsourcing
                                </h1>
                                <hr />
                                <p >
                                    Our Contract & Temporary Staffing solution is designed to support your recruitment goals and provide a flexible, time-saving solution that allows you to focus on growth. Our extensive network includes skilled professionals across various industries, ensuring a versatile Employee Outsourcing option perfect for today's ever-changing economy.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="need-ccomponenets-card hr2">
                        <div className="deatils">
                            <div className="main">
                                <h1>
                                    Recreitment
                                    <br /> Solutions
                                </h1>
                                <hr />
                                <p  >
                                    Our team is comprised of industry-specific experts, not just generalist recruiters. We believe in providing specialized knowledge to ensure those responsible for building your teams are well-versed in their respective fields. Moreover, we go beyond recruitment expertise by leveraging our marketing specialists, who excel in attracting top talent.
                                </p>

                            </div>
                        </div>
                    </div>
                    <div className="need-ccomponenets-card hr3">
                        <div className="deatils">
                            <div className="main">
                                <h1>
                                    Visa
                                    <br /> Services
                                </h1>
                                <hr />
                                <p  >
                                    People Partners is licensed by the Ministry of Labour in the UAE to provide employment visa outsourcing throughout the country. Enabled by an On-Demand Labour supply license, they give clients pain-free visa solutions for their business needs, overcoming quota and corporate headcount restrictions.
                                    <br />
                                    Employment visa sponsorship
                                    <br />
                                    Time-saving solution for PRO and HR/admin support for each employee.
                                    <br />
                                    Experienced and licensed PRO and Government Relations officers.
                                </p>
                                <button>
                                    Learn More
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* Hover */}

            <div className="need-ccomponenets page">
                <div className="need-ccomponenets-main ">
                    <div className="need-ccomponenets-card hr4">
                        <div className="deatils">
                            <div className="main">
                                <h1>
                                    Payroll
                                    <br /> Outsourcing
                                </h1>
                                <hr />
                                <p >
                                    The People Partners Payroll Specialists provide a complete service beyond processing payroll. They advise on gratuity and legislation changes to ensure your staff recognises you as a great employer.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="need-ccomponenets-card hr5">
                        <div className="deatils">
                            <div className="main">
                                <h1>
                                    Hr
                                    <br />Management
                                </h1>
                                <hr />
                                <p  >
                                    We provide a strategic approach to effectively managing people in a company or organisation, such that they help their business gain a competitive advantage.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="need-ccomponenets-card hr6">
                        <div className="deatils">
                            <div className="main">
                                <h1>
                                    Freelancer 
                                    <br />Solutions
                                </h1>
                                <hr />
                                <p  >
                                    People Partners’ Freelancers are qualified, verified and reference-checked. People Partners assigns every client an Account Manager to partner you with one of these professionals. They’re in charge of ensuring that you get the best-matched freelancers for your business and projects.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Packages Benifits */}
            {/* Packages Benifits */}
            {/* Packages Benifits */}

            <div className="page-benifits">
                <div className="main">
                    <div className="content">
                        <div className="heading">
                            <h2>
                                About
                                <span className='orange'>&nbsp;FUTURE INNOVATIVE&nbsp;</span>
                                <br />
                                People Partners
                            </h2>
                        </div>
                        <div className="list">
                            <p>
                                <span className='orange'><strong>
                                    Recruitment & Outsourced Workforce Solutions
                                </strong>  </span>
                                <br />
                                <br />
                                For businesses, we understand the challenges of building a proficient workforce that complements your unique objectives and culture. With our comprehensive outsourced workforce solutions, we help optimize your operations by providing talented individuals who possess the necessary skills and expertise to drive your business forward. By entrusting your staffing needs to us, you can focus on your core business while we find and manage the right people for your team.
                                <br />
                                <br />
                                We are dedicated to assisting individuals, businesses, and anyone seeking exciting new opportunities. We recognize that each person and each business is unique, so we offer tailored solutions to meet your needs.
                            </p>
                        </div>
                    </div>

                    <div className="page-benifits-image">
                        <img src="../assets/png/04.png" alt="" />
                    </div>
                </div>

            </div>

            {/* Page Services */}
            {/* Page Services */}
            {/* Page Services */}

            <div className="page-servies hr">
                <div className="bg">
                    <div className="main">
                        <div className="heading">
                            <h1>
                                Why Choose
                                <span className='orange'>&nbsp;FUTURE INNOVATIVE</span>
                                <br />
                                Solutions?
                            </h1>
                        </div>
                        <div className="content">
                            <h3>
                                With over 13 years of operating in the UAE, we have established a reputable presence and cultivated diverse specialist teams that are experts in their respective fields. Our dedicated professionals take pride in helping you grow your business and advance your career through various solutions on our services page.
                                <br />
                                <br />
                                We encourage you to contact us directly if you are still seeking the right solution. We value your business requirements and would love to have a conversation to understand and cater to your unique needs.
                            </h3>
                        </div>
                    </div>
                </div>

            </div>






        </>
    )
}

export default HrSolution;
